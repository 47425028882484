@use "../../../css/index" as *;

// ! Activity Stream
.note-stream-wrapper {
  width: 100%;
  height: 100%;
  position: relative;
  border-top-right-radius: $tiny;
  border-bottom-right-radius: $tiny;
  border-left: $borderline solid $grey94;
  padding-left: $base;
  > .header {
    background-color: $white100;
    display: flex;
    flex-direction: column;
    border-radius: $tiny;
    padding-top: $base;
    padding-bottom: $small;
    padding-right: $base;
    > .title-row {
      display: flex;
      flex-direction: row;
      width: 100%;
      justify-content: space-between;

      & .title {
        margin: 0;
        font-weight: 600;
        color: $grey60;
        padding-right: 0.375rem;
      }

      & .hide-note-btn {
        visibility: hidden;
      }
    }
  }
  > .body {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
}

// ! Activity Row
.note-row {
  display: flex;
  flex-direction: column;
  position: relative;
  border-bottom: $borderline solid $grey94;
  gap: $small;
  padding-top: $base;
  padding-bottom: $small;
  > .line {
    display: flex;
    flex-direction: row;
    white-space: pre-wrap;
    word-break: break-word;
    gap: $small;

    .date {
      @include caption;
      color: $grey60;
      font-weight: 500;
      display: flex;
      align-items: flex-end;
    }

    .description {
      @include body1;
      color: $black;
      padding-right: $small;
    }

    .link {
      @include link;
      color: $link;
      cursor: pointer;
    }
  }
}
