@use "../../../css/index" as *;

.view-as-readonly-banner {
  position: fixed;
  bottom: 0;
  width: 100%;

  background: $red;
  line-height: 2;
  text-align: center;
  color: white;
  font-size: 15px;
  font-family: sans-serif;
  font-weight: normal;
  z-index: 9;
  
  span{
    font-weight: bold;
  }
}
