@use "../../css/index" as *;

.uc-wrapper {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  background-color: $backgroundARContent;

  // .head defined in MainContentHead.scss

  > .body {
    height: $base;
    flex: 1 1 auto;
    display: flex;

    // .left defined in  MainContentSidebar.scss

    > .right {
      flex: 1 1 auto;
      border-left: $borderline solid $grey90;
      border-top: $borderline solid $grey90;
      width: 100%;
      border-radius: $halfRadius;

      > .content {
        height: 100%;
        background-color: $white100;
        border-radius: $halfRadius;
        width: 100%;
        overflow: auto;

        > div {
          > .os-host {
            > .os-padding {
              > .os-viewport {
                > .os-content {
                  > .body {
                    padding: $base $large 0 $large;
                    height: calc(100vh - 7.125rem);
                    width: calc(100vw - 21.0625rem);
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
