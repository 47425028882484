@use "../typography";

.h1 {
  @include typography.h1;
}

.h2 {
  @include typography.h2;
}

.h3 {
  @include typography.h3;
}

.h4 {
  @include typography.h4;
}

.body1 {
  @include typography.body1;
}

.body2 {
  @include typography.body2;
}

.body3 {
  @include typography.body3;
}

.body4 {
  @include typography.body4;
}

.caption {
  @include typography.caption;
}

.caption-med {
  @include typography.caption-med;
}

.caption-sm {
  @include typography.caption-sm;
}

.link {
  @include typography.link;
}
