@use "../../../../css/index" as *;

.cr-wrapper {
  @include widgetCard(100%, 100%);
  display: flex;

  > .cr-top {
    display: flex;
    align-items: center;
    flex: 0 0 auto;
    > .title {
      @include h4;
      font-weight: 400;
      color: $grey60;
      cursor: pointer;
      white-space: nowrap;
    }
  }
  > .cr-mid {
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 0.4rem;
    > .riskrate-wrapper {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      > .riskrate {
        @include h1;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 600;
        cursor: pointer;
      }
      > .riskrate-percent {
        @include h2;
        line-height: 3.5rem;
      }
    }
    > .improvement-wrapper {
      display: flex;
      align-items: baseline;
      > .improvement-amount-wrapper {
        display: flex;
        align-items: center;
        > .improvement-amount {
          @include h4;
        }
        > .improvement-currency {
          @include body1;
        }
      }
      > .improvement-percentage {
        @include caption-med;
        white-space: nowrap;
      }
    }
  }
  > .cr-bot {
    flex: 1 1 auto;
    width: 100%;
    bottom: 0;
    height: 4.1875rem;
    font-weight: 500;

    svg g rect:hover {
      cursor: pointer;
    }
  }
}
