@use "../../../../css/index" as *;

.container {
  width: 100%;
  .default-styles {
    &-p {
      @include body2;
    }
    &-caption {
      @include caption;
    }
  }
  .default-styles-width {
    width: 100%;
    white-space: nowrap;
    mark {
      background-color: rgba(243, 137, 35, 0.5);
    }
  }
  .overflow-styles {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
