@use "../../../css/index" as *;

.gsw-menu-list {
  margin-top: $tiny;
}

.gsw-dropdown-menuitem-styles {
  @include body1;
  padding: $small $base;
}

.gsw-main-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 31.5rem;
  height: $xlarge;
  background-color: $white100;
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 0.5rem;

  > .gsw-dropdown-container {
    width: fit-content;
    flex: 0 0 auto;
    > .library-button-container {
      width: fit-content;
      .transparent-GSW-button {
        padding: 0 0.625rem;
        background-color: transparent;

        & > svg {
          width: 0.53rem !important;
          > path {
            fill: $grey50;
          }
        }
        > .btn-text {
          @include body1;
          color: $grey50;
        }

        &:hover {
          background-color: transparent;
        }
      }
    }
  }
  > .gsw-input-container {
    flex: 1 1 0;
    padding: 0.625rem;
    width: 100%;

    div.MuiInput-root {
      display: flex;
      flex-direction: row;
    }
    > .gsw-input-styles {
      @include body1;
      width: 100%;
    }
    .gsw-back-icon {
      margin-right: 1.1875rem;
      height: 1rem;
      width: 1.125rem;
    }
    svg {
      cursor: default;
    }
  }
}
.gsw-main-container:focus,
.gsw-main-container:active,
.gsw-main-container:hover {
  border: 1px solid #a1adc4;
  hr {
    border-style: solid;
    border-color: #a1adc4;
  }
}
