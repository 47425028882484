@use "../../../css/index" as *;

// * Accordion Nav Styles
.accordion-nav-overrides.MuiAccordion-root {
  background-color: $backgroundARContent;
  box-shadow: none;
  margin-bottom: $base;

  &::before {
    background-color: transparent;
  }

  .MuiAccordionSummary-root {
    flex-direction: row-reverse;
    padding: 0;
    min-height: $larger;

    p {
      @include caption-med;
      letter-spacing: 0.025rem;
      text-transform: uppercase;
      color: $grey70;
    }

    .MuiAccordionSummary-content {
      margin: 0;
      margin-left: $medium;
    }

    .MuiAccordionSummary-expandIconWrapper {
      width: $larger;
      height: $larger;
      align-items: center;
      justify-content: center;
    }
  }

  .MuiAccordionDetails-root {
    display: flex;
    flex-direction: column;
    gap: $small;
    padding: 0;

    > a {
      text-decoration: none;
    }
  }
}

// * Accordion Nav Item Styles
.accordion-nav-item {
  display: flex;
  align-items: center;
  gap: $medium;
  cursor: pointer;
  padding-right: $base;

  p.body2 {
    @include body2;
    display: block;
    color: $grey30;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    &.active {
      font-weight: 600;
      color: $primary;
    }
  }

  .count-wrapper {
    display: flex;
    justify-content: center;
    width: 3rem;
    margin-left: auto;
  }

  .count {
    @include body2;
    background-color: $grey90;
    padding: 0 $tiny;
    color: $primary;
    border-radius: $atom;
    height: $semi;
  }

  .icon-wrapper {
    width: $larger;
    height: $larger;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: $atom;

    .icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: $large;
      height: $large;
    }

    &-text {
      .icon {
        @include caption-med;
        border-radius: $atom;
        background-color: $grey90;
        color: $grey40;
      }
      &.active .icon {
        background-color: $primary;
        color: $white100;
      }
    }

    &-img {
      .icon svg path {
        fill: $grey40;
      }
      &.active {
        background-color: $primary;
        .icon svg path {
          fill: $white100;
        }
      }
    }
  }

  &:hover {
    .icon-wrapper {
      &-text {
        .icon {
          background-color: $white100;
          color: $primary;
        }
        &.active .icon {
          background-color: $darkBlue;
          color: $white100;
        }
      }

      &-img {
        background-color: $white100;
        .icon svg path {
          fill: $primary;
        }

        &.active {
          background-color: $darkBlue;
          .icon svg path {
            fill: $white100;
          }
        }
      }
    }

    p.body2 {
      color: $primary;

      &.active {
        font-weight: 600;
        color: $darkBlue;
      }
    }
  }
}
