@use "../../../css/index" as *;

.navbar-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  > .navbar-tabs {
    position: relative;
    display: flex;
    width: 80%;
    align-items: center;
    > .navbar-visible-tabs {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-start;
      position: relative;
      gap: $large;
      & .navbar-details {
        display: flex;
        padding: $base $base $small 0;
        align-items: center;
        gap: $base;
        &-divider {
          border-color: $grey30;
          margin: $tiny 0 $tiny 0;
        }

        > .navbar-title {
          @include h4;
          color: $grey30;
        }
        > .navbar-subtitle {
          @include body1;
        }
      }
    }
    > .overflow-dropdown {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      cursor: pointer;
      padding: 0 $small;
    }
  }
  > .navbar-back {
    display: flex;
    align-items: center;
    > .divider {
      width: $borderline;
      height: 1.75rem;
      background-color: $grey80;
      margin: 0 $large;
    }
  }
  .dropdown-wrapper {
    .dropdown {
      @include body2;
      background-color: $white100;
      color: $grey50;
      white-space: nowrap;
      padding-right: 0;
    }
    .dropdown:hover {
      background-color: $white100;
    }
    .dropdown-options {
      background-color: $white100;
      box-shadow: 0 $small $medium rgba(0, 0, 0, 0.1), 0 $tiny $semi rgba(0, 0, 0, 0.1);
      border-radius: $atom;
      height: auto;
      width: auto;
      display: flex;
      flex-direction: column;
      > div {
        display: flex;
        height: 3.125rem;
        align-items: center;
        justify-content: flex-start;
        background: $white100;
        @include body1;
        color: $primary;
        white-space: nowrap;
        width: auto;
        padding: 0 $small;
        text-indent: 0;
      }
    }
  }
}

.navbar-navlink {
  width: auto;
  text-decoration: none;
  height: 100%;
  cursor: pointer;

  &-back {
    width: auto;
    text-decoration: none;
    height: 100%;
    margin-right: $base;
    .back-btn {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      gap: 0.625rem;
      align-items: center;
      justify-content: center;
      height: 100%;
      > svg {
        fill: $grey50;
        width: 0.625rem;
        height: 0.625rem;
      }
      .back-text {
        max-width: 8rem;
        color: $grey50;
        > p {
          @include body2;
          white-space: nowrap;
        }
      }
    }
  }

  &-hidden {
    visibility: hidden;
  }
}
