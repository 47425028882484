@use "../../css/index" as *;

.FourOFour-bg {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: #f3f8fb;
  padding-top: 10vh;

  &-full {
    width: 100vw;
    height: 100vh;
  }
  &-transparent {
    background-color: transparent;
  }
}

.FourOFour-card {
  @include card("26.75rem", "24.625rem");

  background: #ffffff;
  border: $borderline solid #e8eaf2;
  box-shadow: 0 $atom 0.1875rem rgba(111, 130, 142, 0.556818);
  border-radius: $atom;
  padding: 3.75rem;
}

.FourOFour-card-header {
  @include h1;
  margin: $base 0;
  text-align: center;
}

.FourOFour-card-text {
  @include body1;
  text-align: center;
  margin-bottom: $base;
}

.FourOFour-card-span {
  @include body1;
  text-align: center;
  margin-bottom: $base;
}

.FourOFour-card-link {
  @include body1;
  text-align: center;
  margin-bottom: $base;
}
