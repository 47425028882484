@use 'base';

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes scaling {
  0%,
  100% {
    transform: scale(0.2);
    background-color: base.$white100;
    background-color: white;
  }
  50% {
    transform: scale(1);
    background-color: grey;
    background-color: base.$grey80;
  }
}
