@use '../../../../../css/index.scss' as *;

.sort-icon {
  display: flex;
  align-items: center;
  padding: $small;

  > svg {
    > path {
      fill: $grey80;
    }
  }

  &:hover {
    > svg {
      > path {
        fill: $primary;
      }
    }
  }
}
