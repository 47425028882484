@use "../../../css/index" as *;

.content-nav {
  height: 100%;
  width: auto;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-bottom: $atom solid transparent;
  cursor: pointer;

  &:hover > .content-nav-text {
    color: $primary;
  }
  &:disabled > .content-nav-text {
    color: $grey50;
  }
}

.content-nav-active {
  border-bottom: $atom solid $primary;
  > .content-nav-text {
    color: $primary;
  }
}

.content-nav-text {
  @include body2;
  color: $grey50;
  white-space: nowrap;
}
