@use "../../../css/index" as *;

.settings-navbar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  align-content: center;
  padding: 1.5rem 1rem;
  height: 100%;

  .settings-navbar-nav {
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    cursor: pointer;
    svg {
      fill: $primary;
    }
  }

  .settings-navbar-header-text {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    align-content: center;
    margin-left: 1.701rem;

    .header-text {
      font-weight: 600;
      font-size: 1rem;
      line-height: 1.5rem;
      display: flex;
      align-items: center;
      text-align: center;
      color: $grey30;
      margin-right: 1rem;
    }
    .subheader-text {
      font-weight: 400;
      font-size: 0.875rem;
      line-height: 1.25rem;
      display: flex;
      align-items: center;
      color: $grey30;
      margin-left: 1rem;
    }
  }
}
