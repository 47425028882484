@use "../../../css/index" as *;

.drilldown-wrapper {
  display: grid;
  grid-template-rows: 3.428rem auto;
  width: 100%;
  height: 100%;
  > .head {
    border-bottom: $borderline solid $grey94;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: $large;
  }

  > .body {
    width: 100%;
    height: 100%;
    padding: $base $large 0 $large;

    .content {
      display: grid;
      grid-template-rows: 3.5rem auto;
      width: 100%;
      height: 100%;
      gap: $base;
    }
    .content-no-card {
      display: grid;
      grid-template-rows: auto;
      width: 100%;
      height: 100%;
    }

    .content-card {
      background-color: $grey98;
      border-radius: $atom;
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 4rem;
      > .text-span {
        @include body2;
        color: $grey10;
        > .text-label {
          @include body1;
          color: $grey60;
        }
      }
    }
  }
  .table-formatted-cell {
    display: inline-block;
    vertical-align: middle;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    &.light {
      font-weight: 400;
    }
  }
}
