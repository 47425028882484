@use "../../../css/index" as *;

.MuiMenu-overrides.MuiPopover-root {
  .MuiPaper-root {
    box-shadow: 0 $small $medium rgb(0 0 0 / 10%), 0 $tiny $semi rgb(0 0 0 / 10%);
    ul {
      padding: 0;
    }
  }
}
