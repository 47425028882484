@use '../../../../../css/index.scss' as *;

.no-data-wrapper {
  padding-left: $base;
  .no-data {
    @include body1;
    padding: $base 0 $medium 0;
  }
}

.no-approval-data-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  .text {
    color: $grey60;
  }
}

.rt-container {
  height: 100%;
  > .os-host {
    flex: 1;
  }

  .rt-component {
    padding-top: $semi;
    padding-right: $base;

    .rt-wrapper {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      .icon-container {
        padding-left: $base;
        padding-right: $large;
        flex: 0 0 auto;
        .rt-icon {
          height: $xlarge;
          width: $xlarge;
          background-color: $grey90;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;

          svg {
            path {
              fill: $grey70;
            }
          }
        }
      }
      .details-container {
        width: 100%;
        .header {
          .label {
            @include body2;
          }
          .ellipse-icon {
            transform: rotate(90deg);
            cursor: pointer;
          }
        }
        .details {
          display: flex;
          flex-direction: column;
          .row {
            .col-key {
              @include caption;
              color: $grey60;
            }
            .col-val {
              @include body4;
            }
          }
        }
        .rt-btn {
          margin-top: $small;
          .rt-btn-label {
            @include caption-med;
          }
        }

        .header,
        .details .row {
          margin-bottom: $small;
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
      }
    }
  }
  .rt-divider {
    margin-top: $semi;
    margin-bottom: $semi;
    margin-left: $base;
    margin-right: $base;
  }
}
