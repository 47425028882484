@use "../spacing";

$spacings: (
  "sm": spacing.$small,
  "md": spacing.$medium,
  "ba": spacing.$base,
  "lg": spacing.$large,
  "xl": spacing.$xlarge,
  "auto": auto,
);

@each $str, $val in $spacings {
  .m-#{$str} {
    margin: $val;
  }
  .ml-#{$str} {
    margin-left: $val;
  }
  .mr-#{$str} {
    margin-right: $val;
  }
  .mx-#{$str} {
    margin-left: $val;
    margin-right: $val;
  }
  .mt-#{$str} {
    margin-top: $val;
  }
  .mb-#{$str} {
    margin-bottom: $val;
  }
  .my-#{$str} {
    margin-top: $val;
    margin-bottom: $val;
  }
  .p-#{$str} {
    padding: $val;
  }
  .pl-#{$str} {
    padding-left: $val;
  }
  .pr-#{$str} {
    padding-right: $val;
  }
  .px-#{$str} {
    padding-left: $val;
    padding-right: $val;
  }
  .pt-#{$str} {
    padding-top: $val;
  }
  .pb-#{$str} {
    padding-bottom: $val;
  }
  .py-#{$str} {
    padding-top: $val;
    padding-bottom: $val;
  }
  @if $str != auto {
    .gap-#{$str} {
      gap: $val;
    }
  }
}
