@forward "sizing";
@forward "spacing";
@forward "typography";

@use "../typography";

html {
  //To Enable Fluid design uncomment below
  //font-size: calc(0.6838vh + 0.6838vw);

  /* Box-sizing reset */
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

/* Box-sizing reset */
*,
*:before,
*:after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}

body {
  margin: 0;
  font-family: typography.$primaryFontFamily;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
