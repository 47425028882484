@use "../../../css/index" as *;

.arrow-up {
  width: 0;
  height: 0;
  border-left: $small solid transparent;
  border-right: $small solid transparent;
  border-bottom: $small solid $pink;
}

.arrow-up-flipped {
  width: 0;
  height: 0;
  border-left: $small solid transparent;
  border-right: $small solid transparent;
  border-bottom: $small solid $green;
}

.arrow-down {
  width: 0;
  height: 0;
  border-left: $small solid transparent;
  border-right: $small solid transparent;
  border-top: $small solid $green;
}

.arrow-down-flipped {
  width: 0;
  height: 0;
  border-left: $small solid transparent;
  border-right: $small solid transparent;
  border-top: $small solid $pink;
}

// TODO Create styles for right and left (Currently unused anyway...)

.arrow-right {
}

.arrow-left {
}

.arrow-center,
.arrow-center-flipped {
  width: 0.875rem;
  height: $atom;
  background-color: #8592ad;
}
