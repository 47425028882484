@import "../../../css/base";
@import "../../../css/spacing";
@import "../../../css/typography";

.activity-navigation {
  gap: $base;
  height: $larger;
  display: flex;
  flex: 0 0 auto;
  grid-gap: $medium;
  cursor: pointer;
  color: $grey60;
  margin-left: $base;
  padding-left: $base;
  align-items: center;
  border-left: $borderline solid $grey80;
  font-style: normal;
  font-weight: 400;
  font-size: $fontSizeSM;
  line-height: $lineHeightMD;
  .navigation-arrows {
    display: flex;
    align-items: center;
    .navigation-arrow {
      display: flex;
      align-items: center;
      justify-content: center;
      height: $desktopLarge;
      width: $desktopLarge;
      svg {
        fill: $grey60;
        cursor: pointer;
      }
    }

    .navigation-arrow.disabled {
      svg {
        opacity: 0.5;
      }
    }
  }
}
