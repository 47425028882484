@import "../../../../../../../css/index.scss";

.details-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0.625rem $semi 0.625rem $semi;
  gap: $medium;
  > .left {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    .creator {
      @include caption;
      overflow: hidden;
    }
    > .separator {
      display: flex;
      align-items: center;
      padding: 0 $small 0 $small;
    }
    .message {
      @include caption;
      color: $grey40;
      flex-basis: 70%;
    }
  }

  > .right {
    > .date {
      @include caption;
      color: $grey40;
      text-align: end;
    }
  }
}
