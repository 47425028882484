@use "../../../css/index" as *;

.sa-container {
  position: absolute;
  left: 20%;
  top: $xlarge;
  width: 60%;
  height: 9.9375rem;
  background: #ffffff;
  border: $borderline solid $grey93;
  box-sizing: border-box;
  box-shadow: 0 $borderline $atom rgba(0, 0, 0, 0.15);
  border-radius: $tiny;

  .alert-message {
    @include h2;
    color: $grey40;
    font-style: normal;
    font-weight: normal;

    display: flex;
    align-items: flex-end;
    justify-content: center;
    padding-top: 1.0625rem;
  }
  .sa-btn-row {
    display: flex;
    justify-content: center;
    padding-top: $large;
  }
}
