@use "../../../../css/index" as *;
@use "../../../../css/base" as *;

.tr-wrapper {
  @include widgetCard(100%, 100%);
  padding: $medium $base $semi $base;
  > .error-container {
    flex: 1 1 auto;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    > .error {
      @include body1;
    }
  }
  > .tr-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 0 $small 0;
    gap: $small;

    > .title {
      @include h4;
      font-weight: 400;
      color: $grey50;
      cursor: pointer;
      white-space: nowrap;
    }

    > .drop-down-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;

      > .drop-menu {
        background: #f7f7fb;
        border: $borderline solid #dee1ed;
        box-sizing: border-box;
        border-radius: $atom;
        padding: $tiny $small;
        > .body2 {
          @include caption-med;
          color: $primary;
        }

        > svg {
          height: 0.405rem;
          width: 0.663125rem;
          fill: $primary;
        }
      }

      > .sort-up-container {
        display: flex;
        > svg {
          margin-left: $small;
          cursor: pointer;
          transform: scaleY(-1);
          transition: all 0.15s cubic-bezier(0.4, 0, 0.2, 1);
        }
      }
    }

    > .drop-down-wrapper-active {
      > .sort-up-container {
        > svg {
          transform: scaleY(1);
        }
      }
    }
  }

  > .tr-container {
    position: relative;
    width: 100%;
    height: 100%;
    flex: 1 1 auto;
    > .customer-row {
      padding: $medium 0;
      border-bottom: $borderline solid $grey94;
      position: relative;
      > .company-header {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: $tiny;
        > .company-chip {
          width: $large;
          height: $large;
          border-radius: $atom;
          background-color: $grey90;
          display: flex;
          align-items: center;
          justify-content: center;
          text-transform: capitalize;
          @include caption-med;
          color: $grey40;
        }
        > .company-name {
          @include body2;
          color: $grey30;
          padding-left: $small;
        }
      }

      > .company-details {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        > .action-value {
          @include caption;
          color: $grey10;
        }
        > .last-activity-val {
          @include caption-med;
          color: $grey10;
          margin-left: $base;
        }
        > .action-label,
        > .last-activity {
          @include caption;
          color: $grey60;
          padding-left: $small;
        }
      }

      > .customer-menu {
        position: absolute;
        top: 50%;
        transform: translateY(-50%) rotate(90deg);
        right: 0;
        cursor: pointer;
      }
    }
  }

  > .see-more {
    margin-top: $base;
    .see-more-button {
      width: 100%;
      height: $large;
      background: $grey98;
      border: $borderline solid $grey90;
      border-radius: $atom;
      > .btn-text {
        @include caption-med;
      }
    }
  }
}

.tooltip-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  > img {
    &:hover {
      filter: brightness(0%) invert(21%) sepia(11%) saturate(5620%) hue-rotate(170deg) brightness(90%) contrast(89%);
    }

    // Tootip visible on hover of IMG element
    &:hover + div {
      display: block;
    }
  }
  > .tooltip {
    display: none;
    width: 9.375rem;
    background-color: $btn-primaryHover;
    color: $white100;
    text-align: center;
    border-radius: 0.375rem;
    padding: $base $small;
    position: absolute;
    z-index: 1;
    bottom: 150%;
    left: 50%;
    margin-left: -4.6875rem;

    &::after {
      content: "";
      position: absolute;
      top: 100%;
      left: 50%;
      margin-left: -0.3125rem;
      border-width: 0.3125rem;
      border-style: solid;
      border-color: $btn-primaryHover transparent transparent transparent;
      pointer-events: none;
    }
    > .amount {
      @include h2;
      color: $white100;
    }
    > .amountSuffix {
      @include caption-sm;
    }
  }
}
