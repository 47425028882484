@use "../../../css/index" as *;

.fa-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: $grey96;
  width: 30.5rem;
  height: $large;
  border-radius: $atom;
  border: 0.03125rem solid $grey90;
  padding: 0;
  > .fa-name {
    display: flex;
    flex-direction: row;
    font-size: $medium;
    line-height: $base;
    align-items: center;
    width: 90%;
    > .fa-name-label {
      color: $primary;
      font-weight: 700;
      margin: $small $tiny;
    }
    > .fa-name-size {
      margin-left: $atom;
      color: $grey40;
      font-weight: 400;
    }
  }
  > .fa-close {
    display: flex;
    width: 10%;
    justify-content: right;
    margin-right: $small;
    > svg {
      height: 0.9375rem;
      width: 0.9375rem;
      &:hover {
        cursor: pointer;
      }
    }
  }
}
