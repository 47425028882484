@use "../../../../css/base" as *;
@use "../../../../css/spacing" as *;
@use "../../../../css/typography" as *;

@mixin animate($animation, $duration, $method, $times) {
  animation: $animation $duration $method $times;
}

@mixin keyframes($name) {
  @keyframes #{$name} {
    @content;
  }
}

.upload-modal-file {
  width: 24.6875rem;
  height: fit-content;
  padding: $xlarge;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: $white100;
  border-radius: $tiny;
  display: flex;
  flex-direction: column;

  > .subheader {
    font-size: $fontSizeL;
    line-height: $lineHeightXL;
    font-weight: normal;
    margin: 0 0 $xlarge 0;
  }

  > .modal-close-icon {
    position: absolute;
    right: 0;
    top: 0;
    height: $xlarge;
    width: $xlarge;
    display: flex;
    justify-content: center;
    align-items: center;
    > svg {
      fill: $grey40;
      cursor: pointer;
    }
  }

  > h2 {
    margin-bottom: $xlarge;
    color: $grey40;
  }

  > .file-content {
    > .file-children {
      margin-bottom: $base;
    }
    > .file-btn-grp {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-bottom: $xlarge;

      span {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        > .file-btn {
          margin-right: $large;
          width: max-content;
        }
      }
      .caption-sm {
        overflow-wrap: anywhere;
      }
      > .hidden-input {
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
      }
    }
  }

  > .btn-grp {
    display: flex;
    align-items: center;
    justify-content: space-between;
    > .btn {
      padding-left: $medium;
      padding-right: $medium;
    }
    > .icon-btn {
      > .rotate_anim_loader {
        > svg {
          @include keyframes(fade) {
            0% {
              transform: rotate(0deg);
            }
            25% {
              transform: rotate(90deg);
            }
            50% {
              transform: rotate(180deg);
            }
            75% {
              transform: rotate(270deg);
            }
            100% {
              transform: rotate(360deg);
            }
          }
          @include animate(fade, 1s, linear, infinite);
        }
      }
    }
  }
}
