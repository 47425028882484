@use "../../../css/index" as *;

.APHeader-wrapper {
  background-color: $grey98;
  border-radius: $atom;
  height: 3.5rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 4rem;

  > .text-span {
    @include body2;
    color: $grey10;

    > .text-label {
      @include body1;
      color: $grey60;
    }
  }
}
