@use "../../../css/base";
@use "../../../css/spacing";

@mixin card($width: 6.25rem, $height: 6.25rem) {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: $width;
  height: $height;
  background-color: white;
  box-shadow: spacing.$borderline spacing.$borderline 0.1875rem spacing.$atom lightgrey;
  padding: 2.25rem;
}

@mixin widgetCard($width: 6.25rem, $height: 6.25rem) {
  width: $width;
  height: $height;
  box-shadow: 0 spacing.$borderline spacing.$atom rgba(0, 0, 0, 0.15);
  border: spacing.$borderline solid base.$grey90;
  background-color: base.$white100;
  border-radius: spacing.$atom;
  display: grid;
  position: relative;
  padding: spacing.$small spacing.$large 0 spacing.$base;
}
