@use "../../../../css/index" as *;

.wrapper {
  position: flex;
  width: 100%;
  > .add-wrapper {
    background-color: $white100;
    padding: 0.3125rem 0;
    text-align: left;

    > .add-close {
      position: absolute;
      right: $small;
      top: $small;
      border: none;
      background: white;
      cursor: pointer;
    }
    > .add-title {
      @include caption;
      font-weight: 600;
    }
    > .add-btn-grp {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin-top: $small;
      & > * {
        padding: $small;
      }
    }
  }
  .icon-wrapper {
    width: $large;
    height: $large;
    padding-top: $atom;
  }
}
