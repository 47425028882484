@use "../../../css/index.scss" as *;

.gsw-suggestions-container {
  overflow: hidden;
  width: 100%;
  height: 0;
  opacity: 0;
  background-color: $white100;
  position: absolute;
  top: 2.75rem;
  left: 0;
  z-index: 100;
  box-shadow: 0 $small $medium rgba(0, 0, 0, 0.1), 0 $tiny $semi rgba(0, 0, 0, 0.1);
  border-radius: $tiny;
  transition: height 0.7s ease-out 0.125s, opacity 0.3s ease-in 0.2s;

  /*
  * Suggested Results
  */

  > .gsw-no-results-container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    > .no-results-icon {
      background-color: $grey98;
      border-radius: $atom;
      margin-bottom: $large;
      padding: 0.625rem;
    }
    > .no-results-header {
      @include h4;
      margin-bottom: $small;
      color: $black;
    }
    > .no-results-subheader {
      @include body1;
      color: $grey50;
    }
  }

  > .gsw-search-loading-container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    > .gsw-search-loading-icon {
      margin-bottom: $base;
      position: relative;
      height: 9.375rem;
      width: 9.375rem;
    }
    > .gsw-search-loading-header {
      @include h4;
      margin-bottom: $small;
      color: $black;
    }
    > .gsw-search-loading-subheader {
      @include body1;
      color: $grey50;
    }
  }

  > .gsw-suggestion-all-category {
    position: relative;
    width: 100%;
    height: 100%;
    padding: $small 0;
    overflow-y: overlay;
    overflow-x: hidden;
  }

  > .gsw-suggestion-all-category .gsw-suggestion-listItem-container {
    width: 100%;
    height: 3.6rem;
    padding: 0 $base;
    cursor: pointer;
    background-color: $white100;
    transition: background-color 1s linear;

    &:hover {
      background-color: $grey96;
    }
    > div.gsw-suggestion-listItem-row-intermediate {
      padding: $small 0;
      border-bottom: 1px solid #f0f0f0;

      > .gsw-suggestion-listItem-row {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        > .container {
          flex: 1 1 auto;
          width: $base;
          > .gsw-suggestion-listItem-header {
            @include body1;
            color: $black;
          }
          > .gsw-suggestion-listItem-subheader {
            @include caption;
            color: $grey50;
          }
          > .gsw-suggestion-listItem-content-preview {
            @include caption;
            color: $grey50;
            margin-top: 0.25rem;
          }
        }
        > .gsw-suggestion-listItem-info {
          flex: 0 0 auto;
          @include caption;
          color: $grey50;
          padding-left: $base;
        }
      }
    }
  }
  > .gsw-suggestion-all-category .gsw-suggestion-listItem-container.activity-streams {
    height: 4.75rem;
  }
  > .gsw-suggestion-all-category .gsw-suggestion-listItem-container-active {
    background-color: $grey96;
  }

  > .gsw-suggestion-all-category .gsw-suggestion-categoryView-container {
    width: 100%;
    height: $xlarge;
    background-color: $grey98;
    padding: $small $base;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    > .gsw-suggestion-category-header {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      > .gsw-suggestion-category-header-icon {
        > svg {
          width: $base;
          > path {
            fill: $grey50;
          }
        }
      }
      > .gsw-suggestion-category-header-text {
        color: $grey50;
        @include caption-med;
        text-transform: uppercase;
        margin-left: 0.625rem;
        padding-bottom: $atom;
      }
    }

    > .gsw-suggestion-category-view-all-container {
      > .gsw-suggestion-category-view-all-text {
        @include link;
        white-space: nowrap;
        color: $link;
        transition: color 0.6s linear;
        cursor: pointer;
        &:hover {
          color: $snooze;
        }
      }
    }
  }

  > .gsw-suggestion-see-all-results-container {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 3rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.875rem 1.125rem;
    background-color: $white100;
    border-top: $borderline solid $grey94;
    > .gsw-suggestion-all-results-clickable {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      cursor: pointer;
      > svg {
        width: $semi;
        > path {
          fill: $link;
        }
      }
      > .gsw-suggestion-see-all-results-text {
        @include body3;
        color: $link;
        margin-left: 0.625rem;
      }
    }

    > .gsw-suggestion-all-results-muted-text {
      @include body1;
      color: $grey50;
    }
  }

  /*
  * Recent Search Suggestions
  */

  > .gsw-recent-suggestions-container {
    width: 100%;
    height: 100%;
    overflow-y: overlay;
    overflow-x: hidden;
    padding: $small 0rem;

    > .gsw-recent-suggestions-header {
      width: 100%;
      height: $xlarge;
      background-color: $grey98;
      padding: $medium $base;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      > .gsw-recent-suggestions-header-icon {
        height: $base;
        > svg {
          width: $base;
          > path {
            fill: $grey50;
          }
        }
      }

      > .gsw-recent-suggestions-header-text {
        @include caption-med;
        color: $grey50;
        margin-left: 0.625rem;
        margin-top: $atom;
      }
    }

    > .gsw-recent-suggestions-item {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      height: 3.5rem;
      padding: $small $base;
      border-bottom: $borderline solid $grey94;
      cursor: pointer;
      background-color: $white100;
      transition: background-color 1s linear;
      &:hover {
        background-color: $grey96;
      }

      > .gsw-recent-category-icon {
        width: $xlarge;
        height: $xlarge;
        padding: 0.625rem;
        flex: 0 0 auto;
        background-color: $grey98;
        > svg {
          > path {
            fill: $grey60;
          }
        }
      }

      > .gsw-recent-suggestions-item-container {
        margin-left: $medium;
        flex: 1 1 auto;
        width: $base;
        > .gsw-recent-suggestions-item-query {
          > .container > .recent-search-smart-text {
            @include body2;
          }
        }

        > .gsw-recent-suggestions-item-category {
          @include body1;
          color: $grey50;
        }
      }
    }

    > .gsw-recent-suggestions-item-selected {
      background-color: $grey96;
    }

    > .gsw-recent-suggestions-no-recent {
      padding: $medium $base;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      user-select: none;
      > .gsw-recent-suggestions-no-recent-text {
        margin-left: $small;
        margin-bottom: $tiny;
      }
    }
  }
}

.gsw-suggestions-dropdown-enable-true {
  height: 38.75rem;
  opacity: 1;
  overflow: visible;
}
