@use "../../../../../../css/index.scss" as *;

.rac-container {
  position: relative;
  height: 100%;
  width: 100%;
  > .head {
    display: flex;
    padding: 0 $base;
    border-bottom: $borderline solid $grey94;
    > .head-container {
      flex-wrap: nowrap;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
    }
    .drop-menu {
      height: $xlarge;
    }
  }
  .activity-attachment-item {
    padding: $small $base $medium;
    border-bottom: $borderline solid $grey94;
    cursor: pointer;
    &:hover {
      background-color: $grey96;
    }
    > .head {
      display: flex;
      align-items: center;
      justify-content: space-between;
      > .smarty-container {
        width: $base;
        flex: 1 1 auto;
        padding-right: $base;
      }
      p {
        color: $black;
        white-space: nowrap;
      }
    }
    > .body {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0;
      p {
        color: $grey60;
      }
    }
  }
  .loaderPadding {
    padding: $base;
  }
  .infinite-scroll-component {
    height: 37.375rem !important;
  }
  .infinite-scroll-component::-webkit-scrollbar {
    width: $small;
    padding: $atom;
    background: transparent;
    transform: translate(0, 0);
    transition: background-color 0.3s;
  }

  .infinite-scroll-component::-webkit-scrollbar-button {
    height: $xlarge;
  }

  .infinite-scroll-component::-webkit-scrollbar-thumb {
    background: #dee1ed;
    border-radius: $small;

    &::before {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      display: block;
    }
  }

  .infinite-scroll-component::-webkit-scrollbar-track {
    background-clip: content-box;
  }

  .no-attach {
    @include body2;
    padding: $base;
  }
}
