@use "../../../css/index.scss" as *;

.attachment-show-more-chip {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: $atom $small;
  
    width: 4rem;
    height: 1.75rem;
  
  
    border: 1px solid #BDC3DB;
    border-radius: $small;
  }

  .attachment-chip {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: $atom $small;
    gap: $tiny;

    width: 8rem;
    height: 1.75rem;


    border: 1px solid #BDC3DB;
    border-radius: $small;
    flex: none;      
    flex-grow: 0;

  }

  .attachment-chip , .attachment-show-more-chip {
    &:hover {
      background: $grey90;
    }
  }