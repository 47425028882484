@use "../../../css/index" as *;

.drop-menu {
  display: flex;
  align-items: center;
  gap: $small;
  cursor: pointer;

  > p.body2 {
    color: $grey50;
    white-space: nowrap;
  }
  svg {
    width: $small;
    height: $small;
    fill: $grey50;
    transform: scaleY(-1) rotate(-180deg);
    transition: all 0.15s cubic-bezier(0.4, 0, 0.2, 1);
  }
  &-active {
    svg {
      transform: scaleY(-1) rotate(0deg);
      transition: all 0.15s cubic-bezier(0.4, 0, 0.2, 1);
    }
  }

  // added for append icon style used in Add Activity component
  .append-icon {
    height: $large;
    width: $large;
    border-radius: 50%;
    background-color: #dee1ed;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 0.6875rem;

    svg {
      width: $medium;
    }
  }
}
