@use "../../../../css/index" as *;

$btn-iconOnly: $white100;
$btn-iconOnlyHover: $primary;
$btn-iconOnlyHoverBackground: $grey90;

.hoverbar-container {
  display: none;
  background-color: $grey98;
  padding: 0 $semi;
  .btn-transparent:disabled {
    background-color: transparent;
    svg {
      color: $primary;
    }
  }
  .btn-icon-only {
    background-color: $grey98;
    color: $grey90;
    gap: unset;
    grid-gap: unset;
    &:hover {
      background-color: $grey90;
      & > svg > path {
        fill: $primary;
      }
    }
    & > svg > path {
      fill: $grey60;
    }
  }
  .barrier {
    text-align: center;
    margin: auto $medium;
    color: $grey94;
  }
}

.in-table {
  position: absolute;
  right: 0;
}
