@use "../../../../css/index" as *;

.activity-tab-wrapper {
  display: grid;
  grid-template-rows: 3.5rem auto;
  height: 100%;
  width: 100%;
  > .head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 3.5rem;
    padding: 0 $base 0 $base;
    border-bottom: $borderline solid $grey94;
    > .tabs-wrapper {
      display: flex;
      gap: $base;
      height: 100%;
      > .tab {
        display: flex;
        align-items: center;
        height: 100%;
        color: $grey50;
        cursor: pointer;
        border-bottom: $atom solid transparent;
        &:hover {
          color: $primary;
        }
        &-active {
          color: $primary;
          border-bottom: $atom solid $primary;
        }
      }
    }
  }
  .dropdown-wrapper {
    .dropdown {
      @include body2;
      background-color: $white100;
      color: $grey50;
      white-space: nowrap;
      padding-right: 0;
      gap: $tiny;
      &:hover {
        background-color: $white100;
      }
      &:active {
        border: $borderline solid transparent;
      }
      > svg {
        width: $small;
        height: $small;
      }
    }
    .dropdown-options {
      background-color: $white100;
      box-shadow: 0 $small $medium rgba(0, 0, 0, 0.1), 0 $tiny $semi rgba(0, 0, 0, 0.1);
      border-radius: $atom;
      height: auto;
      width: auto;
      display: flex;
      flex-direction: column;
      > div {
        display: flex;
        height: 3.125rem;
        align-items: center;
        justify-content: flex-start;
        background: $white100;
        @include body1;
        color: $primary;
        white-space: nowrap;
        width: auto;
        padding: 0 $small;
        text-indent: 0;
      }
    }
  }
}
