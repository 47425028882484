@import "../../../css/index.scss";

.approval-form-container {
  display: grid;
  grid-template-columns: 5.5rem auto;
  width: 66rem;
  height: 18.25rem;
  background: $white100;
  border: 1px solid $grey94;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15);
  border-radius: 2px;
  padding: $large 0 $large 0;
  .left {
    margin: 0 $large 0 $large;
  }
  .right {
    margin: 0 $large 0 0;
    .header-wrapper {
      margin: 0 0 $semi 0;
      .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        > .req-title {
          @include h3;
          color: $primary;
          margin: 0 0 $base 0;
        }
        > .req-date {
          @include body1;
          color: $grey60;
          margin: 0 0 $base 0;
        }
      }
      .req-message {
        @include body1;
        overflow: auto;
        height: 2.5rem;

        > p {
          margin: 0;
        }
      }
    }
    .ta-input {
      height: 4.5rem;
      margin: 0;
    }
    .btns-wrapper {
      margin: $medium 0 0 0;
      display: flex;
      button {
        margin: 0 $small 0 0;
      }
    }
  }
}
