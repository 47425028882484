@use "../../../../../css/index" as *;

.activity-feed-action-drawer.MuiModal-root {
  .MuiBackdrop-root {
    background-color: transparent;
  }
  .activity-flyout {
    .header {
      color: $primary;
      margin-bottom: $small;
    }
    .subheader {
      color: $grey40;
      margin-bottom: $base;
    }
    .sbIcon-wrapper {
      width: auto;
      .sbIcon-input {
        margin: 0;
      }
    }
    label {
      @include caption-med;
      display: inline-block;
      color: $primary;
      margin-bottom: $tiny;
    }
    label + .ta-wrapper .ta-input {
      margin-top: 0;
      margin-bottom: $base;
    }
    .btn-grp {
      display: flex !important;
      align-items: center;
      justify-content: space-between;
      margin-top: $large;
    }
    .recap-header {
      margin-top: 2.25rem;
      color: $grey60;
    }
    ul.recap-list {
      display: flex;
      flex-direction: column;
      list-style: none;
      padding: 0;
      > li.recap-item {
        display: flex;
        flex-direction: column;
        .head {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: $tiny;
          .caption {
            color: $grey60;
          }
        }
      }
      > li.recap-item:not(:last-child) {
        margin-bottom: $large;
      }
    }
  }
}

.team-member-select + .MuiAutocomplete-popper {
  .MuiPaper-root {
    .MuiAutocomplete-listbox {
      li.MuiAutocomplete-option {
        display: flex;
        flex-direction: row;
        gap: $small;
        padding: 0.5625rem $base;
        .contact-item-profile-tag {
          height: $larger;
          min-width: $larger;
          border-radius: 50%;
          background-color: $grey90;
          display: flex;
          align-items: center;
          justify-content: center;
          svg,
          img {
            fill: $grey60;
            width: 1.125rem;
            height: 1.125rem;
          }
        }

        div.contact-info-item {
          display: flex;
          flex-direction: column;

          span.contact-name {
            font-style: normal;
            font-weight: 400;
            font-size: 0.875rem;
            line-height: $semi;
            display: flex;
            align-items: flex-end;
            color: $primary;
          }
          span.contact-email {
            width: 10rem;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-style: normal;
            font-weight: 500;
            font-size: $medium;
            line-height: $base;
            display: block;
            color: $grey50;
          }
        }
      }
    }
  }
}
