@use "../../css/index" as *;

// ! ProfileBar styles
.profilebar {
  width: 3.5rem;
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: $backgroundAR;
  padding-bottom: $small;
}

// Set profile bar color themes for various workspace types here
.profilebar.ar-theme-bg {
  background-color: $backgroundAR;
}
.profilebar.ap-theme-bg {
  background-color: $backgroundAP;
}

.profilebar-logo {
  margin-bottom: $small;
  cursor: pointer;
}

.switcher-container {
  margin-top: $base;
  display: flex;
  gap: $base;
  flex-direction: column;
  align-items: center;
  flex: 1 1 auto;
  width: 100%;
  .workspace-switcher-item {
    height: $xlarge;
    width: $xlarge;
    background: $white100;
    border-radius: $atom;
    cursor: pointer;

    font-style: normal;
    font-weight: 600;
    font-size: 0.875rem;
    line-height: $large;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    text-transform: capitalize;
    color: $primary;
  }

  .workspace-add-more {
    height: $xlarge;
    width: $xlarge;
    background: transparent;
    border-radius: $atom;

    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    color: $grey60;
    svg {
      fill: $grey60;
    }
  }
  .workspace-add-more:hover,
  .workspace-add-more:active,
  .workspace-add-more:focus {
    background: $primary;
    color: $white100;
    svg {
      fill: $white100;
    }
  }

  .workspace-switcher-item.active {
    background: $primary;
    color: $white100;
  }
}

// ! Home styles
.home-wrapper {
  display: flex;
  width: 100vw;
  height: 100vh;
  overflow: hidden;

  .os-theme-dark > .os-scrollbar,
  .os-theme-light > .os-scrollbar {
    padding: $atom;
  }

  .os-theme-dark > .os-scrollbar-vertical {
    width: 0.625rem;
    > .os-scrollbar-track > .os-scrollbar-handle {
      background: $grey90;
      border-radius: $small;
      min-width: $small;
      max-width: $small;
      min-height: $xxlarge;
      max-height: $xxlarge;
      &::before {
        top: -0.375rem;
        bottom: -$atom;
      }
    }
  }
  .os-theme-dark > .os-scrollbar-horizontal {
    width: 0.625rem;
    > .os-scrollbar-track > .os-scrollbar-handle {
      min-width: $xxlarge;
      max-width: $xxlarge;
      min-height: $small;
      max-height: $small;
      border-radius: $small;
      &::before {
        top: -0.375rem;
        bottom: -$atom;
      }
    }
  }

  .sync-error-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    margin-left: $large;
    margin-right: $large;

    p {
      text-align: center;
      line-height: $large;
    }
  }
}

.settings-btn {
  width: $xlarge;
  height: $xlarge;
  gap: 0;
  margin-top: auto;
  .settings-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    border-radius: $atom;
    svg path {
      fill: $grey40;
    }

    &-active {
      background-color: $primary;
      svg path {
        fill: $white100;
      }
    }
  }

  &:hover .settings-icon {
    background-color: $white100;

    &-active {
      background-color: $darkBlue;
    }
  }
}

// ScrollBar Styles

::-webkit-scrollbar {
  width: $small;
  padding: $tiny;
  background: transparent;
  transform: translate(0, 0);
  transition: background-color 0.3s;
}

::-webkit-scrollbar-button {
  height: $xlarge;
}

::-webkit-scrollbar-thumb {
  background: #dee1ed;
  border-radius: $small;

  &::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: block;
  }
}

::-webkit-scrollbar-track {
  background-clip: content-box;
}
