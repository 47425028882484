@use "../../../../css/index" as *;

.contacts-toast-wrapper {
  background-color: $connectedGreen;
  border-radius: $atom;
  position: absolute;
  top: $atom;
  right: $base;

  > .toast-grp {
    display: flex;
    margin: $atom $large $atom 1.375rem;
    > .toast-container {
      margin-right: $large;
      > .toast-text {
        font-weight: 500 !important;
        font-size: 0.875rem !important;
        line-height: $semi !important;
      }
    }
    > .close-button {
      font-weight: 600 !important;
      font-size: 0.875rem !important;
      line-height: $large !important;
      color: $white100;
      &:hover {
        cursor: pointer;
      }
    }
  }
}
.table-formatted-cell {
  display: inline-block;
  vertical-align: middle;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  .sb-input {
    &.table-input {
      color: $black;
      &.name {
        width: 22rem;
      }
      &.email {
        width: 24rem;
      }
      &.phone {
        width: 12rem;
      }
    }
  }
  &.roles-dropdown {
    width: 20rem;
    .MuiAutocomplete-root {
      > .MuiFormControl-root {
        > .MuiInput-root {
          > .MuiAutocomplete-input {
            color: $black;
            padding-right: 1.75rem !important;
          }
        }
      }
    }
  }
}
