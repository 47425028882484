@use "../../../../css/index" as *;

.insights-summary-container {
  padding: $medium $base $medium $base;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  > .insights-summary-head {
    > .insights-summary-header {
      color: #8592ad;
      > .h4 {
        font-weight: 400;
      }
    }

    > .insights-summary-sub-header {
      padding-top: $small;
    }
  }

  > .insights-summary-body {
    > .item-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: $small 0;
      border-bottom: $borderline solid $grey94;
      > .item-key {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        color: $grey40;
        > svg {
          width: 0.9375rem;
          height: 0.9375rem;
          margin-right: 0.625rem;
        }
      }
    }
    > .item-container:last-child {
      border-bottom: 0;
    }
  }

  > .error_message {
    @include body1;
    text-align: center;
  }
}

.data-error {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
