@use "../../../../css/index" as *;

.id-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: stretch;
  flex-direction: column;
  padding-bottom: $base;

  > .header {
    width: 100%;
    height: 3.5rem;
    border-radius: $atom;
    background: $grey98;
    display: flex;
    align-items: center;
    padding: $medium $base;

    > .title-container {
      flex: 1 1 auto;
      width: $base;
      margin-right: $base;
      .title {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
        @include body2;
      }
    }

    > .connection-chip {
      flex: 0 0 auto;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      > .tag {
        width: $larger;
        height: $larger;
        background-color: $grey90;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: $atom;
        margin: 0;
        text-transform: capitalize;
        @include body2;
      }
      > .name {
        font-weight: 500;
        margin: 0;
        color: $grey10;
        margin-left: $base !important;
        @include body2;
        &:hover {
          cursor: pointer;
        }
      }
    }
    > .due-wrapper {
      display: flex;
      flex: 0 0 auto;
      gap: $tiny;
      margin-right: $xlarge;
      > .due-text {
        @include body1;
        color: $grey60;
      }
      > .due-amount {
        @include body2;
        color: $grey10;
      }
    }
  }

  > .body {
    display: grid;
    grid-template-columns: 1.8fr 1fr;
    flex-grow: 1;
    margin-top: $base;
    background: $white100;
    border: $borderline solid $grey94;
    box-shadow: 0 $borderline $atom rgba(0, 0, 0, 0.15);
    border-radius: $tiny;
    > .payment {
      width: 100%;
      height: 100%;
      border-top-left-radius: $tiny;
      border-bottom-left-radius: $tiny;
      display: flex;
      flex-direction: column;
      > .activity-wrapper {
        padding: $base;
        border-bottom: $borderline solid $grey94;
      }
      > .payment-contact-wrapper {
        width: 100%;
        height: fit-content;
        > .head {
          width: 100%;
          height: fit-content;
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: $large $small 1.75rem $large;
          > .title {
            @include h2;
            display: flex;
            align-items: center;
          }
          > .download-btn {
            margin-left: auto;
          }
        }
        > .body {
          display: flex;
          flex-direction: column;
          gap: 2.75rem;
          padding: $large $base $xlarge $base;
          > .detail-wrapper {
            align-items: flex-start;
          }
        }
      }
    }

    > .details-tab-wrapper {
      border-left: $borderline solid $grey94;
    }
  }
}

// ! Payment Contact Information

.payment-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: $base 0 $base $large;
  gap: $large;
  > .tag {
    width: $xlarge;
    height: $xlarge;
    background-color: $grey98;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: $atom;
    path {
      fill: $grey60;
    }
  }
  > .col-grp {
    display: flex;
    flex-direction: row;
    gap: $xlarge;
    > .col {
      display: flex;
      flex-direction: column;
      height: 100%;
      align-items: flex-start;
      justify-content: center;
      gap: $tiny;
      width: 9.0625rem;
      > .col-title {
        @include caption;
        margin: 0;
        color: $grey60;
      }
      > .col-text {
        @include body1;
      }
      > .col-link {
        @include link;
        color: $link;
      }
    }
  }
}
// ! Invoices Collected
.invoice-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  border-top: $borderline solid $grey90;
  > .title {
    margin: 0;
    color: $primary;
    font-weight: 600;
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: $white100;
    padding: $large 0 $large $large;
  }
  > .os-host {
    height: 15.625rem;
  }
  .row {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: $base;
    color: $grey50;
    border-bottom: $borderline solid $grey94;
    padding: $base $large;
    &:hover {
      cursor: pointer;
      background-color: $grey98;
    }
    &-header {
      padding: $medium $large;
    }
    &:last-child {
      border-bottom: $borderline solid $grey94;
    }
    > .header {
      @include caption-med;
      color: $grey50;
      &-left {
        text-align: left;
      }
      &-right {
        text-align: right;
      }
    }
    > .invoiceNumber {
      @include body2;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      color: $black;
    }
    > .status {
      @include body1;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      color: $black;
    }
    > .paymentAmount {
      @include body1;
      text-align: right;
      color: $black;
    }
    > .outstandingAmount {
      @include body1;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      text-align: right;
      color: $black;
    }
  }
}
