@use "../../../css/index" as *;

.selection-wrapper {
  display: inline-block;
  width: $larger;
  padding-left: $base;
  padding-right: 0rem;
  margin-top: 0.375rem;
  flex-shrink: 0;
}

.main-table-wrapper {
  display: grid;
  grid-template-rows: 5.625rem auto 3rem;
  border: $borderline solid $grey94;
  box-shadow: 0 $borderline $atom rgba(0, 0, 0, 0.15);
  width: 100%;
  height: 100%;
  border-radius: $halfRadius;

  > .os-root {
    > .os-scrollbar-vertical {
      padding-top: 3.125rem;
    }
  }

  > .table-caption {
    display: flex;
    align-items: center;
    padding: 0 1.5625rem 0 1.4375rem;
    gap: $small;

    > .title {
      @include h2;
      margin-right: auto;
    }

    &-no-title {
      justify-content: space-between;
    }

    > .dataset-dropdown-wrapper {
      margin-left: auto;
      width: 10rem;
      cursor: pointer;
    }

    .btn-transparent:disabled {
      background-color: transparent;
      svg {
        color: $primary;
      }
    }
    .btn-icon-only {
      padding-right: $small;
      padding-left: $small;
      gap: 0;
      color: $grey90;
      grid-gap: unset;
      &:hover {
        background-color: transparent;
        & > svg > path {
          fill: $primary;
        }
      }
      & > svg > path {
        fill: $grey60;
      }
    }

    button > .snooze-icon {
      > svg {
        path {
          fill: $grey60;
        }
      }
      &:hover {
        > svg {
          path {
            fill: $primary;
          }
        }
      }
    }
  }

  .table-navigation {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1.5625rem 0 1.4375rem;
    box-shadow: 0 -$borderline $tiny rgba(0, 0, 0, 0.1);
    z-index: 5;

    > .table-navigation-grp {
      display: flex;
      align-items: center;
      width: 100%;

      > .pagination-button {
        @include body2;
        color: $grey60;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 1.75rem;
        padding: 0 $small;
        cursor: pointer;
        &:hover {
          background-color: $grey96;
          color: $primary;
        }
      }

      > .navigation-btn-grp {
        display: flex;
        align-items: center;

        > .navigation-btn {
          width: $xlarge;
          height: $xlarge;
          padding-top: $tiny;
          margin-left: $atom;
          background-color: white;
          border: none;
          cursor: pointer;
          border-radius: $atom;
          &:hover {
            background-color: $grey95;
          }

          > svg {
            fill: $grey60;
          }
          &:disabled {
            opacity: 0.5;
            &:hover {
              background-color: transparent;
            }
          }
        }
      }
    }
    > .table-select-all {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: right;
      text-align: end;
      gap: $base;
      > p {
        display: inline-block;
        @include body1;
        color: $grey50;
        > span {
          font-weight: 600;
          color: $primary;
        }
      }

      > .select-all-button {
        color: $link;
        font-weight: 600;
        &:hover {
          cursor: pointer;
          text-decoration: underline;
        }
      }
    }
  }
  .table-grid {
    display: grid;
    height: auto;
    padding: 0;
    grid-template-rows: 3.125rem 12fr;
    &-full-height {
      height: 100%;
    }
  }

  .table-head {
    display: flex;
    flex-direction: column;
    background-color: white;
    min-width: 70.3125rem;
    position: sticky;
    top: 0;
    z-index: 1;

    > .row {
      height: 3rem;

      // Select the filter button's background
      &:hover > th > div > div > div:first-child {
        background-color: $grey98;
      }

      > .head-col {
        display: flex;
        align-items: center;
        min-width: 0;

        &-first {
          width: $larger !important;
          text-align: left;
          flex-shrink: 0;
        }

        &-last {
          padding-right: $large;
        }

        &-no-checkbox-first {
          padding-left: $large;
        }

        > .cell {
          width: 100%;
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: $small;
          padding: 0.875rem $base;

          &-align-center {
            justify-content: center;
          }

          &-align-left {
            justify-content: flex-start;
          }

          &-align-right {
            justify-content: flex-end;
            margin-right: -2.25rem;
          }
        }
      }
    }
  }

  .col {
    display: flex;
    align-items: center;
    margin: 0;
    color: $grey50;
    @include body2;
    white-space: nowrap;
    overflow: hidden;
    padding: 0;

    &-first {
      flex-shrink: 0;
      overflow: visible;
    }

    &-align-center {
      justify-content: center;
    }

    &-align-left {
      justify-content: flex-start;
      padding: 0.875rem $base;
    }

    &-align-right {
      justify-content: flex-end;
      padding: 0.875rem $base;
    }
    &-last {
      padding-right: $large;
    }
    &-no-checkbox-first {
      padding-left: $large;
    }

    > .sort-placeholder {
      width: $medium;
      height: $tiny;
      visibility: hidden;
      flex-shrink: 0;
    }
    > .filter-placeholder {
      width: 1.75rem;
      height: $base;
      visibility: hidden;
      flex-shrink: 0;
    }
  }

  .row {
    display: flex;
    border-bottom: $borderline solid $grey94;
    cursor: pointer;
    flex-direction: row;
    align-items: center;

    &.read {
      > .col {
        > * {
          font-weight: 400 !important;
        }
      }
    }

    &:hover {
      background-color: $grey98;
      .hoverbar-container {
        display: flex;
        justify-content: flex-end;
      }
    }

    &-no-hover:hover {
      background-color: unset;
      cursor: auto;
    }
  }

  .table-body {
    display: flex;
    flex-direction: column;
    height: 100%;
    min-width: 70.3125rem;
    max-width: 100%;
    z-index: 0;

    .row {
      border-bottom: $borderline solid $grey94;
      min-width: 70.3125rem;
      height: auto;
    }
  }

  .table-dropdown .dropdown {
    color: $grey60 !important;
    height: 1.75rem !important;
    background-color: white;
    font-weight: 500 !important;
    border: none;
    &:hover {
      color: $primary !important;
      background-color: $grey96 !important;
    }
  }
  .table-dropdown.disabled .dropdown {
    color: $grey60 !important;
    &:hover {
      background-color: transparent !important;
    }
  }

  .dropdown-wrapper {
    > div > .dropdown:active {
      border: $borderline solid transparent;
    }
  }

  .table-dropdown {
    .dropdown-options {
      background-color: white;

      > div {
        border: none;
        padding-left: $medium;
        background: none;
      }

      bottom: 118%;
      width: 10.3126rem;
      left: -0.625rem;
      box-shadow: 0 $small $medium rgba(0, 0, 0, 0.1), 0 $tiny $semi rgba(0, 0, 0, 0.1);
      border-radius: $atom;
    }
  }

  .toast-wrapper {
    background-color: $connectedGreen;
    border-radius: $atom;
    position: absolute;
    top: $large;
    right: $base;

    > .toast-grp {
      display: flex;
      margin: $atom $large $atom 1.375rem;
      > .toast-container {
        margin-right: $large;
        > .toast-text {
          font-weight: 500 !important;
          font-size: 0.875rem !important;
          line-height: $semi !important;
        }
      }
      > .close-button {
        font-weight: 600 !important;
        font-size: 0.875rem !important;
        line-height: $large !important;
        color: $white100;
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}

#tooltip-container {
  @include caption;
  position: absolute;
  height: $large;
  text-align: center;
  background-color: $primary;
  color: white;
  padding: $tiny $small;
  border-radius: $atom;
  vertical-align: middle;
}

.invite-flyout-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  z-index: 5;

  > .header {
    @include h3;
    color: $primary;
    margin-bottom: $large;
  }

  > .input-header {
    @include caption;
    font-weight: 600;
    color: $primary;
  }

  > .sb-wrapper {
    &.bottom {
      padding-bottom: $medium;
    }
    &.top {
      padding-top: $xlarge;
    }

    > .sb-label-container {
      > .sb-label {
        > .sb-label-optional {
          font-weight: 500;
        }
      }
    }
    input {
      color: $black;
    }
  }

  > .invite-btn-grp {
    margin-top: 4.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    > .invite-btn {
      padding: $small $medium;

      > .btn-text {
        white-space: nowrap;
      }
    }
  }

  > .add-contact-btn-grp {
    margin-top: $large;
    display: flex;
    align-items: center;
    justify-content: space-between;
    > .invite-btn {
      padding: $small $medium;

      > .btn-text {
        white-space: nowrap;
      }
    }
  }
  > .checkbox-wrapper {
    padding-top: $medium;
    display: flex;
    > .contact-text {
      @include body1;
      height: 100%;
      padding-left: $medium;
      color: $primary;
    }
    > .is-primary-checkbox {
      &[type="checkbox"] {
        cursor: pointer;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        outline: 0;
        background: $white100;
        width: 1.125rem;
        height: 1.125rem;
        border: $atom solid $primary;
        border-radius: $borderline;
        margin: 0;
      }
      &[type="checkbox"]:checked {
        background: $primary;
        border: none;
      }
      &[type="checkbox"]:hover {
        filter: brightness(90%);
        border-color: $primary;
      }

      &[type="checkbox"]:disabled {
        background: #e6e6e6;
        opacity: 0.6;
        pointer-events: none;
      }

      &[type="checkbox"]:after {
        content: "";
        position: relative;
        left: 35%;
        top: 10%;
        width: 35%;
        height: 55%;
        border: solid #fff;
        border-width: 0 $atom $atom 0;
        transform: rotate(45deg);
        display: none;
      }
      &[type="checkbox"]:checked:after {
        display: block;
      }

      &[type="checkbox"]:disabled:after {
        border-color: #7b7b7b;
      }
    }
  }
}
