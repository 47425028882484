@use "../../../css/index" as *;

.error-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  padding: $large;
  > pre {
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.error-wrapper-relative {
  position: relative;
}

.error-cell {
  position: absolute;
  width: 100%;
  height: 100%;
}
