@use "../../../css/index" as *;

.ta-wrapper {
  .ta-input {
    height: 7.5rem;
    width: 100%;
    padding: 0.625rem $medium;
    border: none;
    margin: $small 0;
    background-color: $grey98;
    resize: none;
    color: $primary;
    @include body1;
    border: $borderline solid transparent;

    &::placeholder {
      color: $grey70;
    }

    &:focus {
      outline: none;
      background-color: $grey93;
      border: $borderline solid $grey70 !important;
    }
  }
  .ta-label {
    @include caption-med;
    color: $primary;
  }
  .ta-label + .ta-input {
    margin-top: $tiny;
  }
  .ta-label-container {
    display: inline-block;
    margin-top: $small;
  }
  .ta-label-optional {
    font-weight: 400;
  }

  .ta-error-container {
    > .ta-error {
      color: $red;
      font-size: 10px;
      font-weight: 500;
      line-height: 12px;
    }
  }
}
