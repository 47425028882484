@use "../../css/index" as *;

.activities-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  > .activities-head {
    height: 3.5rem;
    flex: 0 0 auto;
    border-radius: $halfRadius;
    display: flex;
    align-items: center;
    flex-direction: row;
    border-bottom: $borderline solid $grey90;
    padding: 0;
    padding-left: $large;
  }
  > .body {
    flex: 1 1 auto;
    height: $base;
    width: 100%;
    padding: $base $large 0 $large;
  }
}

.table-activity-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: stretch;
  flex-direction: column;
  > .body-table {
    display: flex;
    flex-grow: 1;
    background: $white100;
    border-radius: $fullRadius;
  }
}

.table-button-divider {
  text-align: center;
  margin: auto $medium;
  color: $grey94;
}
