@import "../../../../../../../css/index";

.boarding-initiated {
  display: grid;
  grid-template-columns: 4.25rem 89%;

  &-icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &-body {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    &-header {
      @include h3;
    }
    &-text {
      @include caption;
      font-weight: 400;
      & .green-highlight {
        @include body4;
        color: #24b14b;
      }
    }
  }
}
