@use "../../../../css/index" as *;

.id-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: stretch;
  flex-direction: column;
  padding-bottom: $base;
  > .header-invoices {
    width: 100%;
    height: 3.5rem;
    border-radius: $atom;
    background: $grey98;
    display: flex;
    align-items: center;
    padding: $small $base $small $base;
    > .tag-invoices {
      width: $larger;
      height: $larger;
      background-color: $grey90;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: $atom;
      font-size: 0.875rem;
      line-height: $semi;
      font-family: $primaryFontFamily;
      font-weight: 600;
      text-transform: capitalize;
      margin-left: $xlarge;
    }
    > .title {
      display: flex;
      align-items: center;
      font-size: 0.875rem;
      font-weight: 500;
    }
    > .name {
      font-weight: 500;
      margin: 0;
      color: $grey10;
      margin-left: $base;
      font-size: 0.875rem;
      &:hover {
        cursor: pointer;
      }
    }

    > .due-wrapper-invoices {
      display: flex;
      gap: $small;
      margin-left: auto;
      margin-right: $large;
      > .due-text {
        font-family: $primaryFontFamily;
        font-weight: 400;
        font-style: normal;
        font-size: 0.875rem;
        line-height: $semi;
        color: $grey60;
      }
      > .due-amount {
        font-family: $primaryFontFamily;
        font-weight: 500;
        font-style: normal;
        font-size: 0.875rem;
        line-height: $semi;
        color: $grey10;
      }
    }
  }
  > .body {
    display: grid;
    grid-template-columns: 1.8fr 1fr;
    flex-grow: 1;
    margin-top: $base;
    background: $white100;
    border: $borderline solid $grey94;
    box-shadow: 0 $borderline $atom rgba(0, 0, 0, 0.15);
    border-radius: $tiny;
    > .invoice {
      width: 100%;
      height: 100%;
      border-top-left-radius: $tiny;
      border-bottom-left-radius: $tiny;
      display: flex;
      flex-direction: column;
      > .invoice-contact-wrapper {
        width: 100%;
        height: fit-content;
        > .head {
          width: 100%;
          height: fit-content;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          padding: $base $medium $base $base;
          > .download-btn {
            margin-left: auto;
          }
          border-bottom: $borderline solid $grey94;
        }
        > .body {
          display: flex;
          flex-direction: column;
          gap: 2.75rem;
          padding: $large $base $xlarge $base;
          > .detail-wrapper {
            align-items: flex-start;
          }
        }
      }
    }
    > .details-tab-wrapper {
      border-left: $borderline solid $grey94;
    }
  }
}

// ! Payments Collected
.payment-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  border-top: $borderline solid $grey94;

  .title {
    margin: 0;
    color: $primary;
    font-weight: 600;
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: $white100;
    padding: $large 0 $large $large;
  }
  > .os-host {
    height: 15.625rem;
  }
  .row {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: $base;
    color: $grey50;
    padding: $base $large;
    &:hover {
      cursor: pointer;
      background-color: $grey98;
    }
    padding: 0.875rem $base 0.875rem;
    border-bottom: $borderline solid $grey94;
    > .header {
      @include caption-med;
      font-weight: 500;
      &-left {
        text-align: left;
      }
      &-right {
        text-align: right;
      }
    }
    > .id {
      @include body2;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    > .type {
      @include body1;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    > .date {
      @include body1;
      text-align: right;
    }
    > .amount {
      @include body1;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      text-align: right;
    }
  }
}

.download-icon > path,
.download-icon > path:hover {
  fill: white;
}
