@use "../../../css/index" as *;

.navlink-wrapper {
  width: $xlarge;
  height: $xlarge;
  gap: 0;
  margin-top: auto;
  > .settings-btn {
    .settings-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      border-radius: $atom;
      svg path {
        fill: $grey40;
      }

      &-active {
        background-color: $primary;
        svg path {
          fill: $white100;
        }
      }
    }

    &:hover .settings-icon {
      background-color: $white100;

      &-active {
        background-color: $darkBlue;
      }
    }
  }
}
