@use "../../../css/index" as *;

.MuiAutocomplete-label {
  @include caption-med;
  color: $primary;

  + .MuiAutocomplete-root {
    margin-top: $tiny;
  }
}

// Disable icon flip on dropdown open
.Autocomplete-static-icon {
  > .MuiFormControl-root {
    > .MuiInput-root {
      > .MuiAutocomplete-endAdornment {
        > .MuiAutocomplete-popupIndicator {
          transform: none !important;
        }
      }
    }
  }
}
