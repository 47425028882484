@use "../../../../../css/index" as *;

.activity-profile-tab {
  padding-left: $base;
  padding-bottom: $base;
  > .MuiPaper-root {
    box-shadow: none;
    &::before {
      background-color: transparent;
    }
    .MuiAccordionSummary-root {
      padding-left: 0;
    }
    .MuiAccordionDetails-root {
      padding: 0;
      .detail-wrapper {
        svg {
          width: 1.125rem;
          height: 1.125rem;
        }
      }
      .detail-wrapper:not(:last-child) {
        margin-bottom: $medium;
      }
    }
  }
  .os-host,
  .os-host-overflow {
    max-height: 25rem;
  }

  > .MuiPaper-root:last-child {
    .detail-wrapper {
      .tag {
        border-radius: 50%;
      }
      .title {
        @include body1;
        color: $black;
      }
      .text {
        @include caption;
        color: $grey60;
      }
    }
    .btn {
      padding-left: $small;
      padding-right: $small;
    }
  }
  .activity-contact-list {
    display: flex;
    flex-direction: column;
    gap: $medium;
    max-height: calc(100vh - 25.1875rem);
    .contact-item {
      cursor: pointer;
    }
  }
  .contact-toggle-btn {
    margin-top: $medium;
  }
  svg {
    fill: $grey60;
  }
  .caption-med {
    color: $grey60;
  }
}
