@use "../../../css/index" as *;

.MuiMenuItem-overrides.MuiMenuItem-root {
  @include body1;
  min-height: $xlarge;
  color: $primary;
  background-color: $white100;

  &:hover {
    background-color: $grey96;
    font-weight: 500;
  }
  &:active {
    background-color: $grey93;
  }

  // TODO: Discuss with Gibby the color of a selected dropdown menu
  &.Mui-selected {
    font-weight: 500;
  }
}
