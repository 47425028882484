@use "../../../../css/index" as *;

.checkbox {
  &[type="checkbox"] {
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: 0;
    background: $white100;
    width: 1.125rem;
    height: 1.125rem;
    border: $atom solid $grey60;
    border-radius: $borderline;
    margin: 0;
  }
  &[type="checkbox"]:checked {
    background: $primary;
    border: none;
  }
  &[type="checkbox"]:hover {
    filter: brightness(90%);
    border-color: $primary;
  }

  &[type="checkbox"]:disabled {
    background: #e6e6e6;
    opacity: 0.6;
    pointer-events: none;
  }

  &[type="checkbox"]:after {
    content: "";
    position: relative;
    left: 35%;
    top: 10%;
    width: 35%;
    height: 55%;
    border: solid #fff;
    border-width: 0 $atom $atom 0;
    transform: rotate(45deg);
    display: none;
  }
  &[type="checkbox"]:checked:after {
    display: block;
  }

  &[type="checkbox"]:disabled:after {
    border-color: #7b7b7b;
  }
}

.checkbox-indeterminate {
  &[type="checkbox"] {
    background: $grey60;
  }
  &[type="checkbox"]:after {
    content: "";
    position: relative;
    left: 50%;
    top: 50%;
    width: 0.625rem;
    height: auto;
    border: solid #fff;
    border-width: 0 0 $atom 0;
    transform: translate(-50%, -50%);
    display: block;
  }
}
