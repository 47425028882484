@use "../../../../css/index" as *;

.companyProfile-wrapper {
  display: grid;
  grid-template-columns: $xlarge auto;
  grid-gap: $small;
  align-items: center;
  margin-left: $small;
  cursor: pointer;
  width:17rem;
}

.companyName {
  @include body2;
}

.companyName-container {
  display: flex;
  align-items: center;
  > svg {
    fill: $primary;
    height: 0.354rem;
    width: 0.531rem;
    margin-left: 0.423rem;
  }
}

.accountType {
  @include caption;
  color: $turquoise;
}

.accountType.accounts-payable {
  @include caption;
  color: $purple;
}

.MuiModal-root.MuiMenu-overrides.company-account-selector {
  .MuiPaper-root.MuiPaper-elevation {
    margin-left: 3rem;
    margin-top: -0.938rem;
    ul {
      li {
        height: 2.625rem;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 0.6875rem 1rem;
        gap: 0.5rem;

        .company-logo {
          height: 1.25rem;
          width: 1.25rem;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: $grey90;
          border-radius: 50%;
          border: none;
          cursor: pointer;
          padding: 0;
          overflow: hidden;
          flex-shrink: 0;
          svg {
            height: 0.625rem;
          }
        }
        .company-user-group-info {
          font-size: 0.875rem;
          line-height: 1.25rem;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          color: #143b52;
          gap: 1rem;
          width: 100%;

          .user-group-unread-activity-count {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 0rem 0.25rem;
            gap: 0.625rem;
            height: 1.25rem;
            background: $grey90;
            border-radius: 0.125rem;
            flex: none;
            order: 2;
            flex-grow: 0;
            &.loader {
              width: 2.6875rem;
            }
          }
        }
      }
    }
  }
}
