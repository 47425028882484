@use "../../../css/index" as *;

.detail-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  gap: $large;
  > .tag {
    width: $xlarge;
    height: $xlarge;
    background-color: $grey98;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: $atom;
    flex-shrink: 0;
    > svg {
      fill: $grey60;
    }
  }
  > .no-tag {
    @extend .tag;
    background-color: $white100;
  }
  > .col-grp {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    row-gap: $base;
    column-gap: $large;

    > .col {
      display: flex;
      flex-direction: column;
      height: 100%;
      align-items: flex-start;
      justify-content: center;
      min-width: 9.375rem;
      > .title {
        @include caption;
        color: $grey60;
        display: flex;
        gap: $small;
        align-items: center;
        white-space: nowrap;
      }
      > .text-wrapper {
        display: flex;
        gap: $small;
        > .text {
          @include body1;
        }
        > .fade-in {
          @include body1;
          transition: opacity 0.05s ease;
        }
        > .fade-out {
          @include body1;
          opacity: 0;
          transition: opacity 0.4s ease;
          cursor: default;
        }
        > .link {
          @include body1;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          color: $link;
          cursor: pointer;
        }
        > svg {
          > path {
            fill: $primary;
          }
          &:hover {
            > path {
              fill: $grey60;
            }
          }
          cursor: pointer;
        }
      }
    }
  }
}
