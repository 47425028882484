@use "../../../../css/index" as *;

.activity-feed-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  > .head {
    height: 3.5rem;
    flex: 0 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: $base;
    border-bottom: $borderline solid $grey94;
    > .head-grp {
      display: flex;
      flex-direction: row;
      gap: $small;
      align-items: center;
      .btn-transparent:disabled {
        background-color: transparent;
        svg {
          color: $primary;
        }
      }
      & .detail-icon-btn {
        padding-left: 0.2rem;
        padding-right: 0.2rem;
        color: $grey90;
        gap: unset;
        grid-gap: unset;
        &:hover {
          background-color: transparent;
          & > svg > path {
            fill: $primary;
          }
        }
        & > svg > path {
          fill: $grey60;
        }
      }
      > .MuiIconButton-root {
        width: 1.75rem;
        height: 1.75rem;
        padding: $tiny;
        svg {
          width: $semi;
          height: $semi;
        }
        &.sm {
          svg {
            width: 1.125rem;
            height: 1.125rem;
          }
        }
        &.xs {
          svg {
            width: $medium;
            height: $medium;
          }
        }
      }
      button.add-activity-btn {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: $atom $small;
        height: $large;
        grid-gap: 0.65375rem;
        background: $primary;
        border-radius: $atom;
      }
    }
  }
  > .body {
    height: $base;
    flex: 1 1 auto;
    padding: 0.625rem $base 0.625rem $base;
    min-width: 0;
    overflow: auto;
    background-color: $grey98;
    > div {
      max-height: calc(100vh - 16.125rem);
    }
  }
}
