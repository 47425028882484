@use "../../../../css/index" as *;

.dso-wrapper {
  @include widgetCard(100%, 100%);
  display: flex;

  > .dso-top {
    display: flex;
    align-items: center;
    flex: 0 0 auto;
    > .title {
      @include caption;
      color: $grey60;
      white-space: nowrap;
      white-space: nowrap;
      > .pretext {
        @include h4;
        font-weight: 400;
      }
    }
  }
  > .dso-mid {
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 0.625rem;
    > div > .amount {
      @include h1;
      white-space: nowrap;
    }

    > .mid-row {
      display: grid;
      grid-template-columns: 1fr 1fr;

      > .arrow-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;

        > .arrow-text {
          @include caption-med;
          margin-left: $medium;
        }
      }
    }
  }
  > .dso-bot {
    width: 100%;
    bottom: 0;
    height: 4.1875rem;
    font-weight: 500;
    flex: 1 1 auto;
  }
}

.clickable {
  cursor: pointer;
  svg g rect:hover {
    cursor: pointer;
  }
}

.non-clickable {
  cursor: default;
  svg g rect:hover {
    cursor: default;
  }
}
