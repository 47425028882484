@use "../../../css/index" as *;

.uc-wrapper > .body > .left {
  background-color: $backgroundARContent;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: $medium 0 0 $medium;
  width: 17.5rem;
  flex: 0 0 auto;
  overflow: hidden;
  position: relative;

  .dashboard-btn {
    width: auto;
    text-decoration: none;
    margin-bottom: $base;
  }

  .accordion-list {
    height: 100%;
    width: 100%;
    display: flex;
    margin-bottom: 3rem; // Height of Searchbar

    > .os-host {
      flex: 1;
      .os-scrollbar.os-scrollbar-vertical {
        right: $small;
      }
    }
  }

  .footer {
    height: 3rem;
    border-top: $borderline solid $grey90;
    width: 17.5rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    bottom: 0;
    left: 0;
    background: $backgroundARContent;
    text-align: center;
    vertical-align: middle;

    .search {
      display: flex;
      height: 2.875rem;
      width: 10.9375rem;
      overflow: hidden;

      .sbIcon-input-wrapper {
        height: 2.875rem;
        width: 10.9375rem;
      }

      .sbIcon-wrapper > .sbIcon-input-wrapper > .sbIcon-input {
        background-color: $backgroundARContent !important;
        height: 2.8125rem;
        margin: 0;
        position: absolute;
        color: $primary !important;
        left: 2.625rem;
        @include body1;

        &::placeholder {
          color: $grey60 !important;
        }

        &:hover::placeholder {
          color: $primary !important;
        }

        &:focus {
          border: $borderline solid transparent;

          &::placeholder {
            color: transparent !important;
          }
        }
      }

      .sbIcon-wrapper > .sbIcon-input-wrapper > svg {
        left: $base;
      }
    }

    .sort-wrapper {
      display: flex;
      color: $grey60;

      .sort-btn {
        display: flex;
        align-items: center;
        gap: $small;
        cursor: pointer;
        margin-right: $medium;

        &:hover,
        &-open {
          p.body1 {
            color: $primary;
          }
          svg path {
            fill: $primary;
          }
          svg path:first-child {
            fill: $white100;
          }
        }

        &-active {
          p.body1 {
            color: $primary;
          }
          &:hover,
          &.sort-btn-open {
            svg path {
              fill: $white100;
            }
            svg path:first-child {
              fill: $darkBlue;
            }
          }
        }
      }
    }
  }

  .footer.ap-theme-bg {
    background: $backgroundAPContent;
    .search {
      .sbIcon-wrapper {
        .sbIcon-input-wrapper {
          .sbIcon-input {
            background: $backgroundAPContent !important;
          }
        }
      }
    }
  }
}

// Set profile bar color themes for various workspace types here
.uc-wrapper > .body > .left.ap-theme-content {
  background: $backgroundAPContent;

  .accordion-nav-overrides.MuiAccordion-root {
    background: $backgroundAPContent;
  }
}

.uc-wrapper > .body > .left.ar-theme-content {
  background: $backgroundARContent;
}
