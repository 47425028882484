/** 
 * * Contains base variables that can be imported throughout the css files
 */

// *  Lockstep Default Colors
$primary: #143b52;
$simpleShadow: rgba(0, 0, 0, 0.15);
$backgroundAR: #e1f0fa;
$backgroundARContent: #eff7fc;
$backgroundAP: #e8e3f2;
$backgroundAPContent: linear-gradient(180deg, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0.3) 100%);
$backgroundLightBlue: #f3f8fb;
$pink: #ef3c6b;
$purple: #7d4ed8;
$turquoise: #1fb6c1;
$orange: #f38923;
$green: #25b14b;
$white100: #ffffff;
$grey98: #f7f7fb;
$grey96: #f2f3f8;
$grey95: #eef0f6;
$grey94: #f0f0f0;
$grey93: #e8eaf2;
$grey90: #dee1ed;
$grey85: #c4c4c4;
$grey80: #bdc3db;
$grey70: #a1adc4;
$grey60: #8592ad;
$grey50: #707a8f;
$grey40: #576375;
$grey35: #6b6b6b;
$grey30: #4c4c4c;
$grey10: #1a1a1a;
$black: #000000;
$red: #e54646;
$red90: #ce3f3f;
$redLight: #fceded;
$link: #2d9de7;
$focus: #aab4ca;
$snoozeborder: #79aebf;
$snooze: #126b88;
$onboardBackground: #f3f8fb;
$onboardBorder: #e8eaf2;
$darkBlue: #124564;
$connectedGreen: #24b14b;
$disabled: #e0e0e0;
$purple: #7d4ed8;

// * Aging invoice status indicator colors
$late91: #ef476f;
$late91fill: #fff8f9;
$late61: #e97e00;
$late61fill: #fffdf9;
$late31: #046180;
$late31fill: #effbff;
$late1: #118ab2;
$late1fill: #f0fbff;
$futureDue: #06d6a0;
$futureDuefill: #edfffa;
$active: #1db08a;
$activeFill: #edfffa;
$activeBorder: #79eacd;
$notStarted: #f38923;
$notStartedFill: #fffdf9;
$notStartedBorder: #f4bd7c;
$activityClosedFill: #f2f3f8;
