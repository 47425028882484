@import "../../../../css/base";
@import "../../../../css/spacing";
@import "../../../../css/typography";

@mixin animate($animation, $duration, $method, $times) {
  animation: $animation $duration $method $times;
}

@mixin keyframes($name) {
  @keyframes #{$name} {
    @content;
  }
}

.upload-modal-img {
  width: 24.6875rem;
  min-height: 27.1875rem;
  transition: min-height 10s;
  padding: 2.0625rem $xlarge $xlarge $xlarge;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: $white100;
  border-radius: $tiny;
  display: flex;
  flex-direction: column;

  > .subheader {
    font-size: $fontSizeL;
    line-height: $lineHeightXL;
    font-weight: normal;
    margin: 0;
  }

  > .modal-close-icon {
    position: absolute;
    right: 0.91375rem;
    top: 1.04rem;

    > svg {
      fill: $grey40;
      cursor: pointer;
    }
  }

  > h2 {
    margin-bottom: 1.6875rem;
    color: $grey40;
  }

  > .draggable-area {
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    width: 19.6875rem;
    height: 7.5625rem;
    border: $borderline dashed $grey40;
    margin-top: 2.125rem;
    margin-bottom: 2.375rem;
    padding: 1.5625rem 2.6875rem;

    > p {
      margin: 0.625rem 0 0 0;
    }

    > .hidden-input {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      opacity: 0;
    }
  }

  > .upload_grp {
    display: flex;
    height: $large;
    background: $grey93;
    align-items: center;
    justify-content: space-between;
    padding: $atom $small;
    margin-top: 4.625rem;
    margin-bottom: 5.9375rem;
    color: #a1adc4;
    > .label_grp {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      > .dark-text {
        color: $grey60;
        margin-right: $tiny;
      }
      > .faded-text {
        color: $grey80;
      }
    }
    > .progress_grp {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      > span::before {
        background: white;
      }

      > .progress-close-icon {
        > svg {
          fill: $grey80;
          width: 0.6875rem;
          height: 0.6875rem;
          margin-left: 0.6875rem;
          cursor: pointer;
        }
      }
    }
  }

  > .crop_resize_container {
    > .crop_container {
      width: 19.6875rem;
      height: 19.6875rem;
      position: relative;
      margin-top: 2.125rem;
      margin-bottom: 0.625rem;
      > .cropped_image_display {
        width: 100%;
        height: 100%;
        border-radius: $atom;
      }
    }

    > .crop_reset_text {
      cursor: pointer;
      text-decoration-line: underline;
      color: $link;
      margin-bottom: 1.6875rem;
      &:hover {
        opacity: 0.7;
      }
    }
  }

  > .btn-grp {
    display: flex;
    align-items: center;
    justify-content: space-between;
    > .btn {
      padding-left: $medium;
      padding-right: $medium;
    }
    > .icon-btn {
      > .rotate_anim_loader {
        > svg {
          @include keyframes(fade) {
            0% {
              transform: rotate(0deg);
            }
            25% {
              transform: rotate(90deg);
            }
            50% {
              transform: rotate(180deg);
            }
            75% {
              transform: rotate(270deg);
            }
            100% {
              transform: rotate(360deg);
            }
          }
          @include animate(fade, 1s, linear, infinite);
        }
      }
    }
  }
}
