@use "../../../css/index.scss" as *;

// GSR NAVIGATOR STYLES
.gsr-nav-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 0;
  > .head {
    flex: 0 0 auto;
    height: 3.5rem;
    display: flex;
    align-items: center;
    flex-direction: row;
    border-bottom: $borderline solid $grey90;
    padding: 0;
    padding-left: $large;
  }

  > .body {
    flex: 1 1 auto;
    height: $base;
    width: 100%;
    padding: $large $large 0 $large;
  }
}

// GSR COMPONENT STYLES
.gsr-component-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  .gsr-component-container {
    background-color: $white100;
    border: $borderline solid $grey94;
    box-shadow: 0 $borderline $atom $simpleShadow;
    border-radius: $tiny;
    width: 100%;
    height: 100%;
  }
}
