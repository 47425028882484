@use "../../css/index" as *;

.table-formatted-cell {
  display: inline-block;
  vertical-align: middle;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  &.light {
    font-weight: 400;
  }
  &.bold {
    font-weight: 500;
    color: black;
    > .email-content {
      font-weight: 400;
      color: $grey30;
    }
  }
  // Activity Management (Read/Unread styles)
  > p {
    margin: 0;
  }
  &.unread {
    .subject {
      font-weight: 500;
      color: $black;
    }
    .body {
      font-weight: 400;
      color: $grey30;
    }
  }
  &.read {
    .subject {
      font-weight: 400;
      color: $grey10;
    }
    .body {
      font-weight: 400;
      color: $grey40;
    }
  }

  span {
    &.light {
      color: $grey30;
      font-weight: 400;
    }
  }
  &.read-bold {
    font-weight: 400;
    color: $grey10;
    > .email-content {
      font-weight: 400;
      color: $grey40;
    }
  }
  &.contact-bold {
    font-weight: 400;
    color: $black;
  }
  > .icon-wrapper {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translateY(-50%);
    &.in-line {
      width: $larger;
      height: $larger;
      border-radius: 50%;
      background-color: $grey90;
      > svg {
        fill: $grey70;
      }
    }
  }
  > .user-pic {
    align-items: center;
    justify-content: center;
    transform: translateY(-50%);
    width: $larger;
    height: $larger;
    border-radius: 50%;
    background-color: $grey90;
    > svg {
      fill: $grey70;
    }
  }
  > .user-name {
    padding-left: 3.25rem;
    padding-top: $small;
  }
  &-flag {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    &-icon {
      width: $base;
      height: $base;
      margin-right: $small;
    }
    &-title {
      font-family: Helvetica;
      font-size: $fontSizeSM;
      font-weight: 400;
      line-height: $base;
      letter-spacing: $tiny;
      text-align: center;
    }
  }
  & .purple {
    > span {
      color: #7d4ed8;
    }
    svg > path {
      fill: #7d4ed8;
    }
  }
  & .orange {
    > span {
      color: #f38923;
    }
    svg > path {
      fill: #f38923;
    }
  }
  & .green {
    > span {
      color: #24b14b;
    }
    svg > path {
      fill: #24b14b;
    }
  }
}

.icon-wrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: visible !important;
  height: 2.25rem;
  > .profile-wrapper {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 50%;
    transform: translateY(-50%);
    width: 2.2rem;
    height: 2.2rem;
    border-radius: 50%;
    background-color: $grey90;
    > svg {
      fill: $grey70;
    }
  }
}

.subject-attachment-wrapper {
  display: flex;
  flex-direction: column;
  gap: $small;
  .attachments-container {
    display: flex;
    gap: $small;
    .attachment-show-more-chip {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: $atom $small;

      width: 4rem;
      height: 1.75rem;

      border: 1px solid #bdc3db;
      border-radius: $small;
    }
    .attachment-chip {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: $atom $small;
      gap: $tiny;

      width: 8rem;
      height: 1.75rem;

      border: 1px solid #bdc3db;
      border-radius: $small;
      flex: none;
      flex-grow: 0;
    }
  }
}
.attachment-text {
  font-family: Work Sans;
  font-size: $medium;
  width: 5.625rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 400;
  line-height: $large;
  letter-spacing: 0em;
  text-align: left;
}

.action-handler {
  z-index: 99;
}
