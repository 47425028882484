@use "../../../../css/index" as *;

.filter-wrapper {
  position: relative;
}

.filter-btn-wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: $medium;
  height: $base;
  width: auto;
  color: $white100;
  background-color: transparent;
  border: none;
  border-radius: $atom;
  cursor: pointer;
  padding: $tiny $small;

  &:hover {
    background-color: transparent;
  }
}

.filter-btn-popup {
  position: absolute;
  width: 18rem;
  height: 8.9375rem;
  background-color: $white100;
  border: $borderline solid $grey85;
  top: 80%;

  border-radius: $tiny;
  z-index: 100;
  padding: 1.3125rem $large;
  text-align: left;

  &::before {
    content: "";
    position: absolute;
    bottom: 98.5%;
    left: 50%;
    margin-left: -0.3125rem;
    border-width: 0.3125rem;
    border-style: solid;
    border-color: transparent transparent white transparent;
    z-index: 1;
  }
  &::after {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -0.3125rem;
    border-width: 0.3125rem;
    border-style: solid;
    border-color: transparent transparent #c4c4c4 transparent;
  }
}

.filter-btn-popup-right {
  right: 50%;
  margin-right: -15.625rem;

  &::before {
    left: 13%;
    right: 15.625rem;
  }

  &::after {
    left: 13%;
    right: 15.625rem;
  }
}

.filter-btn-popup-left {
  left: 50%;
  margin-left: -15.625rem;

  &::before {
    left: 15.625rem;
  }

  &::after {
    left: 15.625rem;
  }
}

.filter-btn-popup-center {
  left: 50%;
  margin-left: -9rem;
}

.filter-btn-popup-close {
  position: absolute;
  right: $small;
  top: $small;
  border: none;
  background: white;
  cursor: pointer;

  > svg {
    height: 0.625rem;
    width: 0.625rem;
    fill: $grey50;
  }
}

.filter-btn-popup-title {
  @include caption;
  margin-bottom: $tiny;
  font-weight: 600;
}

.filter-btn-popup-grp {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: $small;
  margin-top: 0.625rem;
  align-items: center;

  .filter-btn-popup-btns {
    height: $larger;
    width: 100%;
  }
  & > * {
    flex: 1 1 0;
    height: $larger;
  }
}

.filter-icon {
  display: flex;
  align-items: center;

  & > path {
    fill: $grey80;
  }
  &:hover > path {
    fill: $darkBlue;
  }
}

.filter-icon-hover {
  & > path {
    fill: $darkBlue;
  }
}

.filter-icon-active {
  & > path {
    fill: $darkBlue;
  }
}
