@use "../../../../css/index" as *;

.customer-action-drawer.MuiModal-root {
  .MuiBackdrop-root {
    background-color: transparent;
  }
  .flyout {
    .header {
      color: $primary;
      margin-bottom: $small;
    }
    .subheader {
      color: $grey40;
      margin-bottom: $large;
    }
    .btn-grp {
      display: flex !important;
      align-items: center;
      justify-content: flex-end;
      margin-top: $large;
    }
  }
}
