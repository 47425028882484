@use "../../../../css/index" as *;

.cs-wrapper {
  @include widgetCard(100%, 100%);
  display: flex;

  > .cs-top {
    display: flex;
    align-items: center;
    flex: 0 0 auto;
    margin-bottom: 0.8125rem;
    > .title {
      @include h4;
      font-weight: 400;
      color: $grey60;
      white-space: nowrap;
    }
  }
  > .cs-mid {
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 0.625rem;
    .cs-mid-title {
      display: flex;
      justify-content: flex-start;
      align-items: flex-end;

      > .large-number {
        @include h1;
        padding-right: 0.375rem;
      }
      > .suffix-container {
        white-space: nowrap;
        display: flex;
        flex-direction: column;

        > .suffix {
          margin-bottom: 0.625rem;
        }
      }
    }
  }

  > .cs-bot {
    flex: 1 1 auto;
    width: 100%;
    bottom: 0;
    height: 4.1875rem;
    font-weight: 500;
  }
}

.clickable {
  cursor: pointer;
  svg g rect:hover {
    cursor: pointer;
  }
}

.non-clickable {
  cursor: default;
  svg g rect:hover {
    cursor: default;
  }
}
