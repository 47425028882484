@use "../../../../css/index" as *;

.userpic-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  height: $xlarge;
  width: $xlarge;
  background-color: $grey90;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  padding: 0;
  overflow: hidden;
  > svg {
    pointer-events: none;
    fill: $grey50;
  }
  > img {
    height: 100%;
    width: fit-content;
    border-radius: 50%;
  }
  &-hoverable {
    &:hover {
      cursor: pointer;
    }
  }
}

.user-icon-default {
  cursor: default;
}
