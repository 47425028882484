@use "../../../../css/index" as *;

.activity-detail-head {
  width: 100%;
  height: 3.5rem;
  flex: 0 0 auto;
  border-radius: $tiny;
  background: $grey98;
  display: flex;
  align-items: center;
  padding: $base;
  flex-shrink: 0;
  > .customer {
    display: flex;
    align-items: center;
    cursor: pointer;
    gap: $base;
    flex: 0 0 auto;
    > .tag {
      width: $larger;
      height: $larger;
      background-color: $grey90;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: $tiny;
      font-size: 0.875rem;
      line-height: $semi;
      margin: 0;
      font-family: $primaryFontFamily;
      font-weight: 600;
      text-transform: uppercase;
    }
    > .customerName {
      @include body2;
      color: $grey10;
    }
  }
  > .amounts-wrapper {
    justify-content: flex-start !important;
    > div {
      align-items: center;
      justify-content: flex-start;
      height: 100%;
      width: 100%;
    }
    .dark {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      flex: 1 1 auto;
      width: $base;
      max-width: fit-content;
    }
    .light {
      flex: 0 0 auto;
    }
  }

  > .amounts-wrapper,
  .mid-section {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: $large;
    margin-right: $xlarge;
    > div {
      display: flex;
      gap: $small;
    }
    .dark {
      @include body1;
      color: $grey10;
      font-weight: 500;
    }
    .light {
      @include body1;
      color: $grey60;
      font-weight: 400;
    }
  }

  .mid-section {
    margin-left: auto;
    flex: 0 0 auto;
  }
  .amounts-wrapper {
    flex: 1 1 auto;
    height: 100%;
  }
}
