@use "../../css/index" as *;

// ! Payment styles
.payments-wrapper {
  display: grid;
  grid-template-rows: 3.5rem auto;
  width: 100%;
  height: 100%;
  padding: 0;
  > .payment-head {
    display: flex;
    align-items: center;
    flex-direction: row;
    border-bottom: $borderline solid $grey90;
    padding: 0;
    padding-left: $large;
  }

  .content {
    display: grid;
    grid-template-rows: 3.5rem auto;
    width: 100%;
    height: 100%;
    gap: $base;
  }
}

.table-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: stretch;
  flex-direction: column;
  padding-bottom: $base;

  > .body-table {
    display: flex;
    flex-grow: 1;
    background: $white100;
    border-radius: $fullRadius;
  }
}
