@import "./../../../css/index.scss";

.activity-detail-container {
  width: 66rem;
  height: 30rem;
  overflow: hidden;
  background: $white100;
  border: 1px solid #f0f0f0;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15);
  border-radius: 2px;
  margin: $large 0 $large 0;

  > .body {
    display: grid;
    grid-template-columns: 1.72fr 1fr;
    > .left {
      border-right: 1px solid $grey94;
      width: 41.75rem;
      > .body {
        height: 21rem;
        padding: 0 $base 0 $base;
        overflow: scroll;
        background: $grey98;
      }
    }
    > .right {
      > .body {
        height: 21rem;

        .attachments-wrapper {
          > .body > div {
            height: 100%;
          }
        }
        .no-attachments-wrapper {
          > .no-attachments {
            height: 100%;
          }
        }
      }
    }
  }

  .left > .head,
  .right > .head {
    display: grid;
    grid-template-rows: 56px auto;
    padding: 0 $base 0 $base;
    border-bottom: 1px solid $grey94;

    .tabs-wrapper {
      display: flex;
      gap: $base;
      height: 100%;
      .tab {
        display: flex;
        align-items: center;
        height: 100%;
        color: $grey50;
        cursor: pointer;
        border-bottom: 2px solid transparent;
        &:hover {
          color: $primary;
        }
        &-active {
          color: $primary;
          border-bottom: 2px solid $primary;
        }
      }
    }
  }
}
