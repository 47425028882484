// ! Global Spacing Variables

// Default Spacings
$borderline: 0.0625rem;
$atom: 0.125rem;
$tiny: 0.25rem;
$small: 0.5rem;
$medium: 0.75rem;
$base: 1rem;
$semi: 1.25rem;
$large: 1.5rem;
$larger: 2rem;
$xlarge: 2.5rem;
$xxlarge: 5rem;

// 1440px and down
$desktopSmall: 0.5rem;
$desktopBase: 1rem;
$desktopLarge: 1.5rem;
$desktopXlarge: 2.5rem;

// 1280px and down
$laptopSmall: 0.5rem;
$laptopBase: 1rem;
$laptopLarge: 1.5rem;
$laptopXlarge: 2.5rem;

// Screen sizes
// TODO: Figure out the correct screen sizes (Current values are placeholders)
$screenSM: 375px;
$screenMD: 425px;
$screenLG: 768px;
$screenXL: 968px;
$screenXXL: 1280px;
$screenFull: 1440px;

// Breakpoints
@mixin smMinBreakPoint {
  @media (min-width: #{$screenSM}) {
    @content;
  }
}

@mixin smMaxBreakPoint {
  @media (max-width: #{$screenSM}) {
    @content;
  }
}

@mixin mdMinBreakPoint {
  @media (min-width: #{$screenMD}) {
    @content;
  }
}

@mixin mdMaxBreakPoint {
  @media (max-width: #{$screenMD}) {
    @content;
  }
}

@mixin lgMinBreakPoint {
  @media (min-width: #{$screenLG}) {
    @content;
  }
}

@mixin lgMaxBreakPoint {
  @media (max-width: #{$screenLG}) {
    @content;
  }
}

@mixin xlMinBreakPoint {
  @media (min-width: #{$screenXL}) {
    @content;
  }
}

@mixin xlMaxBreakPoint {
  @media (max-width: #{$screenXL}) {
    @content;
  }
}

@mixin xxlMinBreakPoint {
  @media (min-width: #{$screenXXL}) {
    @content;
  }
}

@mixin xxlMaxBreakPoint {
  @media (max-width: #{$screenXXL}) {
    @content;
  }
}

@mixin fullMinBreakPoint {
  @media (min-width: #{$screenFull}) {
    @content;
  }
}

@mixin fullMaxBreakPoint {
  @media (max-width: #{$screenFull}) {
    @content;
  }
}
