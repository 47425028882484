@use "../../../../../css/index" as *;

.container-contact {
  border-radius: $tiny $tiny 0 0;
  border: $borderline solid $grey94;
  box-shadow: 0 $borderline $atom $simpleShadow;
  height: 100vh;

  .left {
    display: flex;
    flex-direction: column;
    
    .btn-grp {
      display: flex;
      padding: $small;
      border-bottom: $borderline solid $grey94;
      column-gap: $small;

      .btn {
        padding-left: $small;
        padding-right: $small;
      }
    }
  }
  .details-grp {
    display: flex;
    row-gap: $xlarge;
    flex-direction: column;
    padding: $large 0 0 $large;
  }
}



