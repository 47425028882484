@use "../../../css/base";
@use "../../../css/spacing";
@use "../../../css/typography";

.hover-wrapper {
  display: flex;
  align-items: center;
  gap: spacing.$small;
  background-color: base.$primary;
  border-radius: spacing.$atom;
  color: base.$white100;
  padding: spacing.$tiny;
  box-shadow: 0 spacing.$borderline spacing.$atom rgb(0 0 0 / 15%);
}

.hover-square {
  height: 0.825rem;
  aspect-ratio: 1;
}

.hover-text {
  @include typography.caption;
  text-transform: lowercase;
}

.hover-text::first-line {
  text-transform: capitalize;
}
