@use "../../../css/index" as *;

.signature-settings.container {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  height: 100%;
  justify-content: space-between;

  .signature-settings-body {
    .signature-settings-message-text-container {
      padding: 1.5rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: left;
      background: #ffffff;
      border: 0.031rem solid #f7f7fb;
      box-shadow: 0rem 0.063rem 0.125rem rgba(0, 0, 0, 0.15);
      border-radius: 0.5rem;
      .awsome-text-container {
        display: flex;
        flex-direction: row;
        justify-content: left;
        align-items: center;

        .emoji {
          font-size: 2.033rem;
          margin-right: 1rem;
        }
      }
      .help-text-header {
        font-weight: 600;
        font-size: 1.125rem;
        line-height: 1.15rem;
        display: flex;
        align-items: center;
        color: $grey30;
        margin-bottom: 1rem;
      }
      .help-text-body {
        font-weight: 400;
        font-size: 0.875rem;
        line-height: 1.25rem;
        display: flex;
        align-items: center;
        color: $grey30;
      }
    }
    .signature-container {
      padding: 1.5rem;
      background: #ffffff;
      border: 0.031rem solid #f7f7fb;
      box-shadow: 0rem 0.063rem 0.125rem rgba(0, 0, 0, 0.15);
      border-radius: 0.5rem;
      margin-top: 1rem;
      .signature-text-editor {
        height: 100%;
        position: relative;
        width: 39.375rem;
        .jodit-wrapper {
          width: 39.375rem;
          height: 100%;
          > .jodit-react-container {
            height: 100%;
            .jodit-container {
              background-color: white;
              display: flex;
              flex-direction: column-reverse;
              border: none;
              height: 100% !important;
              .jodit-toolbar__box {
                background-color: white;
                border: none;
                margin: $medium 0 $small 0;
                height: 2.5rem;
                .jodit-toolbar-editor-collection {
                  background: none;

                  .jodit-ui-group {
                    display: flex;
                    align-items: center;
                    .jodit-toolbar-button {
                      align-items: center;
                      display: flex;
                      height: 2rem;
                      justify-content: center;
                      margin: 0;
                      width: 2rem;
                      .jodit-toolbar-button__button {
                        .jodit-toolbar-button__icon {
                          .jodit-icon {
                            height: 1.25rem;
                            width: 1.25rem;
                            fill: #8592ad;
                            stroke: none;
                          }
                        }
                      }
                    }
                    .jodit-ui-separator {
                      align-items: center;
                      background-color: #f0f0f0;
                      display: flex;
                      height: 1rem;
                      margin: 0;
                      width: 0.0625rem;
                    }
                    .jodit-toolbar-button_size_large {
                      height: 2.5rem;
                      min-width: fit-content;
                      button {
                        height: fit-content;
                        min-width: fit-content;
                        padding: 10%;
                      }
                    }
                  }
                }
              }
              .jodit-workplace {
                border: $borderline solid transparent;
                background-color: $grey98;
                height: 100% !important;
                &:focus-within {
                  border: $borderline solid $grey70;
                  background-color: $grey93;
                }
                .na-body-editor {
                  box-sizing: border-box;
                  min-height: 24.5rem !important;
                  padding: 0.625rem $medium;
                  max-height: 24.5rem;
                  overflow-y: auto;

                  p {
                    margin-top: 0;
                    margin-bottom: 0;
                  }
                }
                .temp-body-editor {
                  p {
                    margin-top: 0;
                    margin-bottom: 0;
                  }
                }
              }
            }
          }
        }
        .save-button-container {
          position: absolute;
          bottom: 0rem;
          right: 0rem;
          display: flex;
          gap: 8px;
        }
      }

      .signature-text {
        .signature-header {
          font-weight: 600;
          font-size: 1.125rem;
          line-height: 1.5rem;
          color: #4d4d4d;
        }
        .signature-body-container {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          .signature-body {
            font-style: normal;
            font-weight: 400;
            font-size: 0.875rem;
            line-height: 1.25rem;
            color: #4d4d4d;
            width: 39.375rem;
          }
          .signature-controls {
            display: flex;
            flex-direction: row;
            gap: 0.5rem;
          }
        }
      }
    }
  }
  .signature-settings-help-text-container {
    display: flex;
    flex-direction: row;
    padding: 0.75rem 0.5rem;
    gap: 0.5rem;
    height: 3rem;
    background: #ffffff;
    box-shadow: 0rem 0.063rem 0.125rem rgba(0, 0, 0, 0.15);
    border-radius: 0.25rem 0.25rem 0rem 0rem;
    align-items: center;
    margin-bottom: 1.5rem;
    span {
      font-weight: 400;
      font-size: 0.875rem;
      line-height: 1.25rem;
      display: flex;
      align-items: center;
      color: $grey60;
    }
  }
}

.modal-signature-settings {
  top: -50%;
  .MuiPaper-root.MuiPaper-elevation.MuiDialog-paper {
    width: 33rem;
    .MuiDialogContent-root {
      p {
        font-style: normal;
        font-weight: 400;
        font-size: 0.75rem;
        line-height: 1rem;
        color: #4d4d4d;
      }
    }
    .MuiDialogActions-root {
      justify-content: flex-end;
    }
  }
}
