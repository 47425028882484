@use "../../../css/index.scss" as *;

#lockstep-bar {
  .bar-icon-btn {
    border-radius: $atom;
    transition: none;
    padding: $small;
    &.active {
      background: $primary;
      svg path {
        fill: $white100;
      }
    }
    &:hover {
      background-color: $white100;
    }
  }
}

#lockstep-bar-menu {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(auto, 1fr);
  padding: $medium;
  gap: $medium;

  .MuiMenuItem-root {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: $small;
    border-radius: $fullRadius;

    p {
      @include body1;
      color: $primary;
    }

    .MuiIconButton-root {
      background-color: $grey90;
    }

    &:hover {
      background-color: rgba(25, 118, 210, 0.08);
    }
  }
}
