@use "../../../../css/index" as *;

.sort-btn-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: none;
  height: $base;
  max-width: 70%;
  padding: 0;
  gap: $small;
  cursor: pointer;

  &:hover > .sort-btn-icon {
    display: block;
  }
}

.sort-btn-wrapper-disabled {
  cursor: auto;
}

.sort-btn-icon {
  display: none;
  & > path {
    fill: #a1adc4;
  }

  &-active {
    display: block;
    & > path {
      fill: black;
    }
  }
}

.sort-btn-label {
  @include caption;
  color: $grey50;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &:hover + .sort-btn-icon {
    display: block;
  }
}
