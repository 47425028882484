@use "../../css/index.scss" as *;

// ! Invoice styles
.invoices-wrapper {
  display: grid;
  grid-template-rows: 3.5rem auto;
  width: 100%;
  height: 100%;
  padding: 0;
  > .invoice-head {
    display: flex;
    align-items: center;
    flex-direction: row;
    border-bottom: $borderline solid $grey90;
    padding: 0;
    padding-left: $large;
  }
}

// ! Nav Link styles
.invoices-navlink {
  width: auto;
  text-decoration: none;
  height: 100%;
  margin-right: $large;
}
.navlink-back {
  width: auto;
  text-decoration: none;
  height: 100%;
  margin-left: $large;
  margin-right: $large;
}
.back-btn {
  display: flex;
  flex-direction: row;
  gap: 0.625rem;
  align-items: center;
  justify-content: center;
  height: 100%;
  > p {
    @include body2;
    color: $grey50;
  }
  > svg {
    fill: $grey50;
  }
}

// ! Table styles
.invoices-formatted-cell {
  display: inline-block;
  vertical-align: middle;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}
