@use "../../../../css/index.scss" as *;

.attachments-dialog {
  display: grid;
  height: 41.25rem;
  grid-template-columns: 17.5rem 62.5rem;

  .sidebar-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .sidebar-items {
      display: flex;
      flex-direction: column;
      padding: $large $large $large $base;

      .item {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 0.875rem;
        cursor: pointer;
        &-info {
          display: flex;
          align-items: center;
          > .icon {
            display: flex;
            align-items: center;
            justify-content: center;

            height: $larger;
            width: $larger;
            border-radius: $atom;
            > svg {
              height: $large;
              width: 1.125rem;
              > path {
                fill: $grey40;
              }
            }
          }
          > .title {
            @include body2;
            margin-left: $medium;
            color: $grey30;
          }
        }
        &-count {
          @include body2;
          background-color: $grey90;
          border-radius: 2px;
          padding: 0 $tiny 0 $tiny;
        }
      }
      // update icon & text style for selected
      .selected {
        .icon {
          background-color: $primary;
          > svg {
            > path {
              fill: white;
            }
          }
        }
        .title {
          @include body3;
          margin-left: $medium;
          color: $grey30;
        }
      }
    }
    .sidebar-action {
      padding: $base;
      .upload-btn {
        width: 100%;
      }
    }
  }

  .content-wrapper {
    display: grid;
    grid-template-rows: auto 4.5rem;

    .content-header {
      padding: $large $large $large $base;
    }
    .content-body {
      display: flex;

      .table {
        flex-wrap: wrap;
        overflow-wrap: break-word;
        padding: 0 0 0 $base;
        height: 100%;
        width: 61.5rem;
      }
    }
    .content-footer {
      align-items: center;
      justify-content: space-between;
      padding: $base;
      > .left {
        @include body1;
      }
      > .right {
        justify-content: space-between;
        span:first-child {
          button {
            margin-right: $small;
          }
        }
      }
    }

    .content-footer,
    .left,
    .right {
      display: flex;
    }
  }
}
