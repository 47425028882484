@use "../../../css/index" as *;

.library-modal-close-icon {
  position: absolute;
  right: $base;
  top: $base;
  svg {
    fill: #576375;
  }
}
