@import "../../../../../../../css/index.scss";

.accordion-summary {
  display: flex;
  align-items: center;
  margin-left: $semi;
  width: 100%;

  > .accordion-caret-icon {
    display: flex;
    align-items: center;
    vertical-align: middle;
    justify-content: center;
    height: 1.25rem;
    width: 1.25rem;
    transform: rotate(90deg);

    &:hover {
      background-color: $grey90;
      border-radius: $tiny;
    }
  }

  // point downwords
  > .expanded {
    transform: rotate(180deg);
    background-color: $grey90;
    border-radius: $tiny;
    transition: all 0.2s ease;
  }
  // point right
  > .close {
    transform: rotate(90deg);
    background-color: transparent;
    transition: all 0.2s ease;
  }

  > .base-wrapper {
    width: 100%;

    > .details-wrapper {
      padding-left: $small;
    }
  }
}

.accordion-content {
  padding: 0 0 0.625rem 1.25rem;
  margin-left: 1.75rem;
  > p {
    @include body1;
    vertical-align: middle;
    margin-bottom: $small;

    > b {
      word-break: break-all;
    }
  }
}
