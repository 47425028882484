@use "../../../../css/index" as *;

.carousel-container {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  margin: $medium 0;

  > .carousel-item {
    flex: 0 0 100%;
    width: 100%;
    height: 100%;
    opacity: 1;
    position: absolute;
    left: 0;
    top: 0;
    transition: left 0.8s, opacity 0.5s;
    z-index: 2;
    border: $borderline solid #dee1ed;
    box-shadow: 0 $borderline $atom rgba(0, 0, 0, 0.15);
    border-radius: $tiny;
    overflow-x: hidden;
    overflow-y: hidden;
  }

  > .inactive-item-l {
    left: -100%;
    opacity: 0;
    z-index: 1;
  }
  > .inactive-item-r {
    left: 100%;
    opacity: 0;
    z-index: 1;
  }
}
.carousel-container-dimensions {
  height: 25rem;
  width: 100%;
}

.navigator {
  position: absolute;
  display: flex;
  top: 0.9375rem;
  left: 0.625rem;
  z-index: 3;
  > .navigator-buttons {
    background-color: transparent;
    height: fit-content;
    padding: 0 !important;
    margin-right: 1.168125rem;
    > svg {
      width: 0.35375rem;
      height: 0.530625rem;
      fill: #8592ad;
    }

    &:hover {
      background-color: transparent;
    }
  }
}

.navigator-top-start {
  left: 0.625rem;
  right: auto;
  > .navigator-buttons {
    margin-left: 0;
    margin-right: 1.168125rem;
  }
}

.navigator-top-center {
  right: 0;
  left: 0;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  > .navigator-buttons {
    margin-right: 1.168125rem;
    margin-left: 0;
  }
}

.navigator-top-end {
  right: 0.625rem;
  left: auto;
  > .navigator-buttons {
    margin-right: 0;
    margin-left: 1.168125rem;
  }
}

.navigator-middle-start {
  top: 50%;
  transform: translateY(-50%);
  left: 0.625rem;
  right: auto;
  margin-top: auto;
  margin-bottom: auto;
  height: fit-content;
  > .navigator-buttons {
    margin-left: 0;
    margin-right: 1.168125rem;
  }
}

.navigator-middle-center {
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  left: 0;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  > .navigator-buttons {
    margin-right: 1.168125rem;
    margin-left: 0;
  }
}

.navigator-middle-end {
  top: 50%;
  transform: translateY(-50%);
  right: 0.625rem;
  left: auto;
  > .navigator-buttons {
    margin-right: 0;
    margin-left: 1.168125rem;
  }
}

.navigator-bottom-start {
  top: auto;
  bottom: 0.8125rem;
  left: 0.625rem;
  right: auto;
  margin-top: auto;
  margin-bottom: auto;
  height: fit-content;
  > .navigator-buttons {
    margin-left: 0;
    margin-right: 1.168125rem;
  }
}

.navigator-bottom-center {
  top: auto;
  bottom: 0.8125rem;
  right: 0;
  left: 0;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  > .navigator-buttons {
    margin-right: 1.168125rem;
    margin-left: 0;
  }
}

.navigator-bottom-end {
  top: auto;
  bottom: 0.8125rem;
  right: 0.625rem;
  left: auto;
  > .navigator-buttons {
    margin-right: 0;
    margin-left: 1.168125rem;
  }
}
