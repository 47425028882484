@import "./../../css/index.scss";

.redirect-form-loader {
  height: 50vh;
}

.approval-container {
  width: 69rem;
  height: 46.75rem;
  background-color: white;
  display: flex;
  flex-direction: column;
  padding-top: $large;
  align-items: center;
  justify-content: space-between;
}
