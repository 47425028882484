@use "../../../../css/index" as *;

.na-body-editor-wrapper {
  display: flex;
  gap: $small;
  flex-direction: column;
  &:hover {
    background-color: $grey96;
  }
  &:focus-within,
  &:active {
    background-color: $grey93;
  }
  &-disabled {
    background-color: $grey98;
    color: $grey70;
    gap: 0;
    cursor: not-allowed;
  }
}

.na-body-editor-toolbar {
  display: flex;
  flex-direction: row;
  position: absolute;
  bottom: $small;
  background-color: $white100;
  &-disabled {
    display: none;
  }
  > .rdw-inline-wrapper,
  .rdw-history-wrapper,
  .rdw-custom-wrapper {
    display: flex;
    align-items: center;
    height: $larger;
    > .rdw-option-wrapper {
      display: flex;
      margin: 0;
      height: $larger;
      width: $larger;
      align-items: center;
      justify-content: center;
      > img {
        width: $semi;
      }
      > .na-body-message-attachment-input {
        display: none;
      }
      &.rdw-custom-option-wrapper {
        display: flex;
        height: $larger;
        width: $larger;
        border-radius: $atom;
        margin: 0;
        align-items: center;
        justify-content: center;
        > .rdw-custom-option-file {
          height: $semi;
          width: 1.1875rem;
          > svg {
            width: $semi;
            height: $semi;
          }
          &-selected {
            height: $semi;
            width: 1.1875rem;
            > svg {
              width: $semi;
              height: $semi;
              path {
                &:nth-child(2) {
                  fill: $primary;
                }
              }
            }
          }
        }
      }
      &:hover {
        cursor: pointer;
      }
    }
  }
  > .rdw-list-wrapper {
    display: flex;
    align-items: center;
    width: $borderline;
    height: $base;
    background-color: $grey94;
    margin: $small 0.625rem;
  }
}

.na-body-wrapper {
  -ms-overflow-style: none;
  scrollbar-width: none;
  display: flex;
  flex-direction: column;
  gap: $small;
  border-radius: $atom;
  border: $borderline solid transparent;
  position: relative;
  &::-webkit-scrollbar {
    display: none;
    align-items: center;
    display: flex;
    height: 2rem;
  }
  > .na-body-attachments {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: $small;
    width: 31.5rem;
    position: absolute;
    bottom: 4rem;
  }
  > .fa-close {
    position: fixed;
    bottom: 0;
  }
  > .rdw-custom-wrapper {
    position: absolute;
    bottom: 2.4%;
    left: 36%;
    z-index: 9;
    align-items: center;
    display: flex;
    height: 2rem;
    .rdw-option-wrapper.rdw-custom-option-wrapper {
      align-items: center;
      border-radius: 0.125rem;
      display: flex;
      height: 2rem;
      justify-content: center;
      margin: 0;
      width: 2rem;
      input {
        display: none;
      }
      .rdw-custom-option-file,
      .rdw-custom-option-file-selected {
        display: flex;
      }
      .rdw-custom-option-file-selected {
        > svg {
          width: $semi;
          height: $semi;
          path {
            &:nth-child(2) {
              fill: $primary;
            }
          }
        }
      }
    }
  } > .jodit-react-container {
      .jodit-container {
        background-color: white;
        display: flex;
        flex-direction: column-reverse;
        border: none;
        .jodit-toolbar__box {
          background-color: white;
          border: none;
          margin: $medium 0 $small 0;
          height: 2.5rem;
          .jodit-toolbar-editor-collection {
            background: none;

            .jodit-ui-group {
              display: flex;
              align-items: center;
              .jodit-toolbar-button {
                align-items: center;
                display: flex;
                height: 2rem;
                justify-content: center;
                margin: 0;
                width: 2rem;
                .jodit-toolbar-button__button {
                  .jodit-toolbar-button__icon {
                    .jodit-icon {
                      height: 1.25rem;
                      width: 1.25rem;
                      fill: #8592ad;
                      stroke: none;
                    }
                  }
                }
              }
              .jodit-ui-separator {
                align-items: center;
                background-color: #f0f0f0;
                display: flex;
                height: 1rem;
                margin: 0;
                width: 0.0625rem;
              }
              .jodit-toolbar-button_size_large {
                height: 2.5rem;
                min-width: fit-content;
                button {
                  height: fit-content;
                  min-width: fit-content;
                  padding: 10%;
                }
              }
            }
          }
        }
        .jodit-workplace {
          border: $borderline solid transparent;
          background-color: $grey98;
          &:focus-within {
            border: $borderline solid $grey70;
            background-color: $grey93;
          }
          .na-body-editor {
            box-sizing: border-box;
            min-height: 24.5rem !important;
            padding: 0.625rem $medium;
            max-height: 24.5rem;
            overflow-y: auto;

            p {
              margin-top: 0;
              margin-bottom: 0;
            }
          }
        }
      }
    }
  .mention-hoc {
    ul.mentions {
      list-style: none;
      display: flex;
      flex-direction: column;
      padding: 0;
      background-color: #fff;
      color: rgba(0, 0, 0, 0.87);
      transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      font-weight: 400;
      font-size: 1rem;
      line-height: 1.5;
      letter-spacing: 0.00938rem;
      overflow: auto;
      border-radius: 0 0 0.125rem 0.125rem;
      box-shadow: 0 0.5rem 0.75rem rgb(0 0 0 / 10%), 0 0.25rem 1.25rem rgb(0 0 0 / 10%);
      max-height: 15.625rem;
      position: absolute;
      top: 1.25rem;
      z-index: 9;

      li.mention {
        display: flex;
        flex-direction: row;
        padding: 0.625rem;
        align-items: center;
        cursor: pointer;

        .user-pic {
          max-width: 2.5rem;
          min-width: 2.5rem;
          height: 2.5rem;
          background-color: $grey96;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          svg {
            fill: #8592ad;
          }
        }
        .mention-data {
          display: flex;
          flex-direction: column;
          margin-left: 0.625rem;
          .name {
            overflow-wrap: break-word;
            max-width: 18.75rem;
          }
          .email {
            overflow-wrap: break-word;
            max-width: 18.75rem;
          }
        }
       
      }
      li.mention:focus,
      li.mention:hover {
        background-color: $grey94;
      }

      li.mention-disabled {
        display: flex;
        flex-direction: row;
        padding: 0.625rem;
        align-items: center;

        .user-pic {
          max-width: 2.5rem;
          min-width: 2.5rem;
          height: 2.5rem;
          background-color: $grey96;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          svg {
            fill: #8592ad;
          }
        }
        .mention-data {
          display: flex;
          flex-direction: column;
          margin-left: 0.625rem;
        }
        .mention-data-loading{
          .name {
            padding: 0.25rem 0.5rem;
          }
          .email {
            padding: 0.25rem 0.5rem;
          }
        }
      }
    }
    ::-webkit-scrollbar-button {
      height: 5rem;
    }
    > .jodit-react-container {
      .jodit-container {
        background-color: white;
        display: flex;
        flex-direction: column-reverse;
        border: none;
        .jodit-toolbar__box {
          background-color: white;
          border: none;
          margin: $medium 0 $small 0;
          height: 2.5rem;
          .jodit-toolbar-editor-collection {
            background: none;

            .jodit-ui-group {
              display: flex;
              align-items: center;
              .jodit-toolbar-button {
                align-items: center;
                display: flex;
                height: 2rem;
                justify-content: center;
                margin: 0;
                width: 2rem;
                .jodit-toolbar-button__button {
                  .jodit-toolbar-button__icon {
                    .jodit-icon {
                      height: 1.25rem;
                      width: 1.25rem;
                      fill: #8592ad;
                      stroke: none;
                    }
                  }
                }
              }
              .jodit-ui-separator {
                align-items: center;
                background-color: #f0f0f0;
                display: flex;
                height: 1rem;
                margin: 0;
                width: 0.0625rem;
              }
              .jodit-toolbar-button_size_large {
                height: 2.5rem;
                min-width: fit-content;
                button {
                  height: fit-content;
                  min-width: fit-content;
                  padding: 10%;
                }
              }
            }
          }
        }
        .jodit-workplace {
          border: $borderline solid transparent;
          background-color: $grey98;
          &:focus-within {
            border: $borderline solid $grey70;
            background-color: $grey93;
          }
          .na-body-editor {
            box-sizing: border-box;
            min-height: 24.5rem !important;
            padding: 0.625rem $medium;
            max-height: 24.5rem;
            overflow-y: auto;

            p {
              margin-top: 0;
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
}

.jodit.jodit-popup-container.jodit-box {
  .jodit-popup.jodit-popup_strategy_leftbottom {
    .jodit-popup__content {
      .jodit-ui-form {
        .jodit-ui-block.jodit-ui-block_align_left.jodit-ui-block_size_middle:nth-child(3),
        .jodit-ui-checkbox.jodit-ui-checkbox_checked_false {
          display: none;
        }

        .jodit-ui-block.jodit-ui-block_align_full {
          display: flex;
          align-items: center;
          justify-content: space-evenly;
          grid-gap: 0.625rem;
          button {
            width: 100%;
          }

          .jodit-ui-button_variant_default {
            background: #f7f7fb;
            border: 0.063rem solid #dee1ed;
            border-radius: 0.125rem;
            font-weight: 600;
            font-size: 0.875rem;
            line-height: 1.5rem;
            color: #143b52;
          }
          .jodit-ui-button_variant_primary {
            background: #124564;
            border-radius: 0.125rem;
            font-weight: 600;
            font-size: 0.875rem;
            line-height: 1.5rem;
            color: #ffffff;
          }
        }
      }
    }
  }
}

