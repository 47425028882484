@use "../../../../../css/index" as *;

.contact-detail-head {
  width: 100%;
  height: 3.5rem;
  flex: 0 0 auto;
  border-radius: $tiny;
  background: $grey98;
  display: flex;
  align-items: center;
  padding: $base;
  flex-shrink: 0;
  justify-content: space-between;
  margin-bottom: $base;
  .contact-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: $base;

    > .profile-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      top: 50%;
      width: 2.2rem;
      height: 2.2rem;
      border-radius: 50%;
      background-color: $grey90;
      > svg {
        fill: $grey70;
      }
    }
    > .contactName {
      width: auto;
      height: $large;
      @include body2;
    }
  }

  .date-company-wrapper {
    display: flex;
    align-items: center;
    flex-direction: row;
    column-gap: $xlarge;
    > .date-wrapper {
      display: flex;
      flex-direction: row;
      column-gap: $tiny;
      .dark {
        @include body1;
        color: $grey10;
        font-weight: 500;
      }
      .light {
        @include body1;
        color: $grey60;
        font-weight: 400;
      }
    }
    .customer {
      display: flex;
      align-items: center;
      cursor: pointer;
      gap: $base;
      flex: 0 0 auto;
      > .tag {
        width: $larger;
        height: $larger;
        background-color: $grey90;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: $tiny;
        @include body3;
        text-transform: uppercase;
      }
      > .customerName {
        @include body2;
        color: $grey10;
      }
    }
  }
}
