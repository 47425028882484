@use "../../../../css/index" as *;

.balance-amount-dark {
  @include body1;
  color: $grey10;
  font-weight: 500;
  margin-right: $tiny;
}
.balance-amount-light {
  @include body1;
  color: $grey60;
  font-weight: 400;
}

.other-connection {
  display: flex;
  .text {
    @include caption-med;
    font-size: 0.875rem;
    color: $grey50;
    padding: 0 0 0 $base;
  }
  .column-icon {
    display: flex;
    align-items: center;
    padding-left: 0.375rem;
    > svg {
      margin-left: calc($small / 2);
      height: $base;
      width: $base;
      path {
        fill: $grey50;
      }
    }
  }
}
