@use "../../css/index" as *;
@use "../../css/spacing" as *;

.dashboard-container {
  display: flex;
  flex-direction: row;
  height: 100%;

  .scrollable-container {
    height: 100%;
    width: $base;
    flex: 1 1 auto;
    .scroll-component {
      padding: $xlarge $xlarge 0 $xlarge;
      height: 100%;
    }
    .db-wrapper {
      overflow-x: hidden;
      flex: 0 0 auto;
      display: flex;
      width: 100%;
      flex-direction: column;

      > .head {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
        margin-bottom: 1.875rem;

        > .welcome-main {
          @include h2;
          color: $primary;
        }

        > .welcome-sub {
          @include h3;
          font-weight: 400;
          color: $grey35;
        }
      }
      > .content {
        display: flex;
        > .row {
          flex: 1 1 auto;
          width: 100%;

          > .widget {
            display: flex;
            margin-bottom: $semi;
            > .dashboard-widget-container {
              width: 100%;
              height: 16.625rem;
              box-shadow: 0 $borderline $atom rgb(0 0 0 / 15%);
              border: $borderline solid #dee1ed;
              background-color: #ffffff;
              border-radius: $atom;
              display: flex;
              flex-direction: column;
              position: relative;
              padding: 1.1875rem $large $large $large;
              > .padding-sm {
                padding-left: $atom;
              }
              > .dashboard-widget-body-small-margin {
                margin-top: 0.8125rem !important;
              }
              > .dashboard-widget-body {
                width: 100%;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                margin-top: 1.125rem;
                flex: 1 1 auto;
                .row-widget-divider {
                  height: 100%;
                  margin: 0 $xlarge;
                }
                .aging-wrapper,
                .cr-wrapper {
                  box-shadow: 0 0 0 0;
                  border: 0;
                  padding: 0;
                  flex: 1 1 auto;
                  display: flex;
                  width: $base;
                  flex-direction: column;
                }
                .dso-wrapper,
                .cs-wrapper {
                  box-shadow: 0 0 0 0;
                  border: 0;
                  padding: 0;
                  flex: 0 0 auto;
                  display: flex;
                  width: 14.4rem;
                  flex-direction: column;
                }
              }
              > .dashboard-widget-body > div:last-child {
                flex: 1 1 auto;
                max-width: 100%;
              }
            }
          }
        }
      }
    }
  }
  .insights-wrapper {
    flex: 0 0 auto;
    width: 22rem;
    background: #ffffff;
    border-left: $borderline solid #dee1ed;
    height: calc(100vh - 3.5625rem);
    > .insights-container {
      height: 100%;
      .os-content {
        height: fit-content !important;
        padding: $semi $base;
      }
      .head {
        .title {
          @include h3;
          display: flex;
          align-items: flex-end;
          color: #143b52;
        }
      }

      .top-customers {
        .tr-wrapper {
          min-height: 27.75rem;
          position: relative;
          display: flex;
          flex-direction: column;
          > .loading-wrapper {
            left: 0;
            top: 0;
          }
        }
      }
    }
  }
}
