@use "../../../css/index" as *;

// ! Default Chip
.chip {
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  border-radius: $atom;
  align-items: center;
  padding: 0 $small;
  gap: $small;
  position: relative;
  width: auto;
  & > svg {
    z-index: 2;
  }
}

// ! Chip size variants
.chip-lg {
  padding: $tiny $small;
  height: $large;
}

.chip-sm {
  height: $base;
}

// ! Chip color variants
.chip-bg {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  border-radius: $atom;
}

//Active activity
.chip-active {
  color: $active;
  border: 0.03125rem solid $activeBorder;
  & > .chip-bg {
    background-color: $activeFill;
  }
  & > svg > path {
    fill: $active;
  }
}

.chip-snoozed {
  color: $snooze;
  border: 0.03125rem solid $snoozeborder;
  & > .chip-bg {
    background-color: $late31fill;
  }
  & > svg > path {
    fill: $snooze;
  }
}

//Not started activity
.chip-not-started {
  color: $notStarted;
  border: 0.03125rem solid $notStartedBorder;
  & > .chip-bg {
    background-color: $notStartedFill;
  }
  & > svg > path {
    fill: $notStarted;
  }
}

//closed activity
.chip-activity-closed {
  color: $primary;
  border: 0.03125rem solid $black;
  & > .chip-bg {
    background-color: $activityClosedFill;
  }
  & > svg > path {
    fill: $primary;
  }
}
//Caution icon past due invoices
.chip-caution-info {
  z-index: 2;
  display: flex;
  align-items: center;
}

// Open Invoice Status
.chip-open {
  color: $futureDue;
  border: 0.03125rem solid $futureDue;

  & > .chip-bg {
    background-color: $futureDuefill;
  }

  & > svg > path {
    fill: $futureDue;
  }
}

// Late 1-30
.chip-late1 {
  color: $late1;
  border: 0.03125rem solid $late1;
  & > .chip-bg {
    background-color: $late1fill;
  }
  .chip-caution-info {
    & > svg > path {
      fill: $late1;
    }
  }
}

// Late 31-60
.chip-late31 {
  color: $late31;
  border: 0.03125rem solid $late31;
  & > .chip-bg {
    background-color: $late31fill;
  }
  .chip-caution-info {
    & > svg > path {
      fill: $late31;
    }
  }
}

// Late 61-90
.chip-late61 {
  color: $late61;
  border: 0.03125rem solid $late61;
  & > .chip-bg {
    background-color: $late61fill;
  }
  .chip-caution-info {
    & > svg > path {
      fill: $late61;
    }
  }
}

// Late 91+
.chip-late91,
.chip-archived {
  color: $late91;
  border: 0.03125rem solid $late91;
  & > .chip-bg {
    background-color: $late91fill;
    opacity: 1;
  }
  .chip-caution-info {
    & > svg > path {
      fill: $late91;
    }
  }
}

.chip-closed {
  color: $black;
  & > .chip-bg {
    background-color: $grey90;
    opacity: 1;
  }
  & > svg > path {
    fill: $grey90;
  }
}

.chip-grey {
  color: $black;
  & > .chip-bg {
    background-color: $grey90;
    opacity: 1;
  }
  & > svg > path {
    fill: $grey90;
  }
}

// ! Chip text
.chip-text {
  font-family: $primaryFontFamily;
  font-style: normal;
  font-weight: 500;
  font-size: $medium;
  line-height: $base;
  margin: 0;
  z-index: 2;
}
