@use "../../../css/index" as *;

.noData-wrapper {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: $large;
  > p {
    @include body1;
    text-align: center;
    > a {
      color: $link;
    }
  }
}

.noData-wrapper-relative {
  position: relative;
}

.noData-cell {
  position: absolute;
  width: 100%;
  height: 100%;
}
