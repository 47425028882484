@use "../../../css/index" as *;
@use "../../../css/base" as *;

.profile-wrapper {
  height: 100%;

  &-disable-nav {
    & > .profile-detail,
    & > .edit-profile {
      margin: 0;
    }
  }
}

.caption-primary-dark {
  color: $primary;
  margin-bottom: $tiny;
}

.profile-head,
.side-section-head {
  height: 3.4375rem;
  border-bottom: $borderline solid $grey94;
  pointer-events:none;
}

.profile-head {
  margin: 0 0 $base 0;
  padding: 0 $large;
  > .navbar-wrapper {
    > .navbar-back {
      > .navbar-navlink-back {
        margin-right: 0;
      }
    }
  }
}

.side-section-head {
  padding-left: $base;
}

.profile-detail-container {
  padding: 0 $large 0 $large;

  > .inner-header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: $base 0 $base 0;
    background-color: $grey98;

    > .right {
      padding: $base;
      display: flex;
      flex-direction: row;
      align-items: center;

      > .edit-profile-btn {
        padding: $small;
      }

      .last-updated {
        display: flex;
        align-items: center;
        margin-right: $base;

        > p {
          margin-block: 0;
          margin: 0;
        }

        > .last-update-date {
          font-size: 0.875rem;
          font-style: normal;
          font-weight: 500;
          line-height: $semi;
          letter-spacing: 0em;
          text-align: right;
          margin-right: calc($base/4);
        }

        > .last-update-text {
          font-size: 0.875rem;
          font-style: normal;
          font-weight: 400;
          line-height: $semi;
          letter-spacing: 0em;
          text-align: right;
          color: $grey60;
        }
      }
    }

    > .left {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      padding-left: $base;

      > .user-name,
      > .company-name {
        font-weight: 500;
        margin: 0 0 0 $base;
        line-height: $lineHeightMD;
        font-style: normal;
      }

      > .user-role,
      > .workspace-members {
        font-weight: 400;
        margin: 0 $base 0 $base;
        line-height: $lineHeightMD;
        font-style: normal;
      }

      > .user-role,
      .user-name {
        font-size: $fontSizeSM;
      }
    }
  }

  .content-nav.content-nav-active {
    cursor: default;
  }

  .btns-grp {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;

    > .profile-edit-btn {
      padding: 0 $small 0 $small;
      margin: 0 0 0 $small;
      border-radius: $atom;

      > svg {
        height: 0.9375rem;
        width: 0.9375rem;
      }
    }

    > .primary-border {
      border: $borderline solid $primary;
    }

    > .secondary-border {
      border: $borderline solid #dee1ed;
    }
  }

  > .my-profile-detail,
  .company-profile-detail {
    position: relative;
    display: grid;
    grid-template-columns: 1.72fr 1fr !important;
    flex-grow: 1;
    border: $borderline solid $grey94;
    background: $white100;
    border-radius: $tiny $tiny 0 0;
    box-shadow: 0 $borderline $atom rgba(0, 0, 0, 0.15);

    > .profile-detail-container {
      border-right: $borderline solid $grey94;
      padding: 0 $large 0 $large;
    }
  }
  .password-reset-flyout-wrapper {
    > .header {
      @include h3;
      color: $primary;
      white-space: nowrap;
      margin-bottom: $small;
    }
    > .description {
      @include body1;
      color: $grey40;
    }
  }
}

.info-row {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;

  > p {
    margin: 0 0 $semi 0;
    font-size: $fontSizeSM;
    width: 100%;
  }

  > .info-header {
    @extend h2;
    margin-bottom: $atom;
    width: 100%;
  }
}

.profile-detail,
.edit-profile {
  height: 100%;
  background: $white100;

  .container {
    height: calc(100vh - 19.375rem);
    padding-right: $small;

    &-split {
      display: grid;
      grid-template-columns: 1.8fr 1fr;
      flex-grow: 1;
      .right {
        border-left: $borderline solid #f0f0f0;
      }
    }
  }

  .last-field {
    padding-bottom: calc($base * 3) !important;
  }

  .profile-edit-bar {
    border-bottom: $borderline solid $grey94;
    padding: $base 0 0 0;
    .erp-contact-header {
      color: $primary;
      font-size: 0.875rem;
      line-height: $semi;
      width: 65%;
    }

    .btn-grp {
      display: flex;
      flex-direction: row;
      justify-content: left;
      gap: $small;
    }

    .last-updated {
      display: flex;
      flex-direction: column;
      @include caption;
      color: $grey50;
      text-align: right;
      &.no-button {
        margin-left: auto;
      }
    }
  }
  .user-avatar-info {
    padding: 0 0 $large 0;
    color: $primary;
    gap: $large;

    > .avatar-title {
      font-weight: 500;
      color: $grey60;
      line-height: $lineHeightSM;
      font-size: $medium;
      margin: $semi 0 $medium 0;
    }

    > .user-avatar-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      width: 100%;

      > .avatar-view {
        height: $xxlarge !important;
        width: $xxlarge !important;
        background-color: $grey90;
        margin-right: $small;
        padding: 0;

        > svg {
          height: 2.625rem;
          width: $larger;
          margin: auto;
          padding: $tiny;
          color: $grey70;
        }
      }
      .upload-description {
        margin: auto 0 auto $small;
        @include body2;
        color: $grey50;
        vertical-align: center;
      }
    }
  }
  .user-info-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
    .detail-wrapper {
      padding: $semi 0 $semi 0;
      width: auto;
    }
    // override default Detail styles
    .detail-wrapper > .col-grp > .col > .text-wrapper {
      overflow-wrap: anywhere;
    }
    .detail-wrapper > .col-grp > .col > .text-wrapper > .link {
      width: 10rem;
    }
    .detail-wrapper > .col-grp > .col {
      min-width: auto !important;
      max-width: 10.4375rem !important;
    }
    .detail-wrapper:not(:first-child) {
      padding-left: $semi;
    }
    .detail-wrapper:not(:last-child) {
      padding-right: $semi;
    }
    .company-description > .detail-wrapper > .col-grp > .col > .text-wrapper {
      width: 29.375rem !important;
    }
  }
  .form-row {
    display: flex;
    flex-direction: row;
    padding: $base 0 $base 1.75rem;

    .address-checkbox-row {
      margin-bottom: $small;
    }

    .sb-wrapper {
      > .sb-label-container {
        margin-bottom: $small;
      }
      &.notes-field {
        width: 0.375rem;
        .sb-input {
          resize: none;
          height: 4.875rem;
          text-indent: 0;
          padding: 0.625rem $small;
        }
      }
    }
  }

  .user-info-row:not(:last-child),
  .user-avatar-info,
  .form-row:not(:last-child),
  .form-address-row {
    border-bottom: $borderline solid $grey94;
  }

  .form-row,
  .form-address-row {
    display: flex;
    flex-direction: column;
    padding: $large 0 $large 0;
    > .form-sub-row {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }

    .form-sub-row:not(:first-child) {
      padding-top: $small;
    }
    .form-sub-row:not(:last-child) {
      padding-bottom: $small;
    }

    .detail-wrapper > .col-grp > .col {
      min-width: auto !important;
      max-width: 10.4375rem !important;
    }
    .detail-wrapper:not(:first-child) {
      padding-left: $semi;
    }
    .detail-wrapper:not(:last-child) {
      padding-right: $semi;
    }
  }

  .form-row > .form-sub-row > * {
    flex: 1 1 0;
  }

  .form-address-row > .form-sub-row {
    .col-we {
      width: calc(50% - $small);
    }

    .col-wl {
      display: flex;
      width: calc(66.66% - $small);

      > * {
        flex: 1 1 0;
      }
    }
    .col-wm {
      width: calc(33.33% - $small);
    }
  }

  .form-row > .form-sub-row,
  .form-address-row > .form-sub-row > .col-wl,
  .form-address-row > .form-sub-row > .col-wm {
    > .form-input:not(:last-child) {
      padding-right: $small;
    }

    > .form-input:not(:first-child) {
      padding-left: $small;
    }
  }

  .address-checkbox-row {
    display: flex;
    align-items: center;

    .checkbox-text {
      margin-left: $medium;
      font-size: $fontSizeXS;
    }
  }
}

.button-row {
  display: flex;
}

.zero-padding {
  padding: 0 !important;
}

.side-content-wrapper {
  padding: 1.1875rem 2.375rem 0 $base;
  position: relative;
  height: 100%;
  width: 100%;
  > .content-title {
    @include h4;
    font-weight: 400;
  }

  > .content-text {
    font-size: $fontSizeSM;
    margin-bottom: $semi;
  }
}

.divider {
  margin: $large $semi $large $semi;
}

.dialog-cancel-close-icon {
  position: absolute;
  right: 0.91375rem;
  top: 1.04rem;

  > svg {
    fill: $grey40;
    cursor: pointer;
  }
}

.company-activity-tab {
  .body {
    .head {
      p:nth-child(even) {
        flex: 0 0 auto;
        white-space: nowrap;
        text-align: right;
        padding-left: $small;
      }
      p:nth-child(odd) {
        flex: 1 1 auto;
        width: 0.625rem;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }
  }
}
