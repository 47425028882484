@use "../../../css/index" as *;
@use "../../../css/spacing" as *;

.os-padding,
.os-scrollbar {
  z-index: 2 !important;
}

.na-wrapper {
  &-closed {
    display: none;
  }
  position: fixed;
  z-index: 10;
  bottom: 0;
  right: $xlarge;
  width: 40.375rem;
  min-height: 30rem;
  max-height: 49.125rem;
  display: flex;
  border-radius: 0.3125rem 0.3125rem 0 0;
  flex-direction: column;
  background-color: $white100;
  box-shadow: 0 $small $medium rgba(0, 0, 0, 0.1), 0 $tiny 1.125rem rgba(0, 0, 0, 0.1);

  > .na-header {
    width: 100%;
    height: $xlarge;
    border-radius: 0.3125rem 0.3125rem 0 0;
    background-color: $primary;
    display: flex;
    flex-direction: row;
    > .na-header-title-text {
      @include body2;
      width: calc(90% - 3rem);
      color: $white100;
      margin: 0.625rem $base;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    > .na-header-close-wrapper {
      width: 10%;
      display: flex;
      align-items: center;
      justify-content: right;
      margin-right: $base;
      > svg {
        fill: $grey90;
        &:hover {
          cursor: pointer;
        }
      }
    }
  }

  > .na-body {
    margin: 0;
    color: $primary;
    display: flex;
    flex-direction: column;
    min-height: 27.5rem;
    > .na-body-child {
      margin: $small $small 0;
    }
    > .na-body-to,
    .na-body-cc,
    .na-body-bcc {
      display: flex;
      flex-direction: row;
      max-width: 39.375rem;
      > .na-body-toline,
      .na-body-ccline,
      .na-body-bccline,
      .na-body-activity-type-line {
        min-height: $xlarge;
        width: 100%;
        background-color: $grey98;
        border: $borderline solid transparent;
        border-radius: $atom;
        max-height: 4.875rem;
        display: flex;
        overflow: scroll;
        -ms-overflow-style: none;
        scrollbar-width: none;
        &::-webkit-scrollbar {
          display: none;
        }
        > .na-body-to-To,
        .na-body-to-Cc,
        .na-body-to-Bcc {
          display: flex;
          align-items: center;
          height: $xlarge;
          margin-left: $medium;
          margin-right: $medium;
        }
        > .na-body-to-search,
        .na-body-cc-search,
        .na-body-bcc-search {
          display: flex;
          align-items: center;
          height: $xlarge;
          margin-left: $medium;
          margin-right: 0.625rem;
        }
        &:hover {
          background-color: $grey96;
        }
        .MuiAutocomplete-root {
          min-height: $xlarge;
          max-width: 37.875rem;
          width: 100%;
          height: auto;
          .MuiFilledInput-root {
            min-height: $xlarge;
            height: auto;
            background: none;
            .MuiChip-root {
              border-radius: $atom;
              padding: 0;
              border: none;
              background-color: $grey96;
              .MuiChip-label {
                @include body2;
                color: $primary;
                padding: $atom 0.625rem $atom $tiny;
                font-weight: 400;
                font-size: 0.875rem;
                line-height: $semi;
              }
              .MuiSvgIcon-root {
                height: $semi;
                width: $semi;
                fill: $grey60;
              }
            }
          }

          .MuiFilledInput-input {
            min-height: $xlarge;
            @include body2;
            background: none;
            &:hover {
              -webkit-text-fill-color: rgba(0, 0, 0, 1) !important;
            }
          }
        }
        &:focus-within {
          border: $borderline solid $grey70;
          background-color: $grey93;
        }
        .na-body-to-extra-recipients {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          height: $xlarge;
          .na-body-to-extra-cc,
          .na-body-to-extra-bcc {
            @include body3;
            font-size: $medium;
            line-height: $base;
            display: flex;
            align-items: center;
            color: $primary;
            margin-left: $tiny;
            cursor: pointer;
            padding: $tiny $small;
            height: $large;
            border: $borderline solid $grey90;
          }
          .na-body-to-extra-bcc {
            margin-right: $tiny;
          }
        }
      }
      > .na-body-toline-reply {
        margin-left: 0.5625rem;
        height: $xlarge;
        white-space: nowrap;
      }
      > .na-body-activity-type-line {
        .drop-menu {
          padding-left: $medium;
          padding-right: 0.625rem;
          width: 100%;
          .body2 {
            width: 100%;
            text-transform: capitalize;
            color: $primary;
          }
        }
        .drop-menu svg {
          all: unset;
        }
        .drop-menu {
          .append-icon {
            height: $large;
            width: $large;
            border-radius: 50%;
            background-color: $grey90;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 0.1875rem;
            flex-shrink: 0;
            svg {
              width: $medium;
            }
          }
        }
      }
      > .na-body-activity-type-line.disabled {
        pointer-events: none;
        .drop-menu {
          .body2 {
            color: $grey50;
          }
        }
        .drop-menu svg {
          fill: $grey50;
        }
      }
    }
    > .na-body-title {
      height: $xlarge;
    }
    > .hyperlink-modal-container {
      position: absolute;
      z-index: 10;
      height: 90%;
      width: 100%;
      bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 1rem;
      cursor: pointer;
      visibility: hidden;
      opacity: 0;
      transition: all 0.35s ease-in;
    }
    > .hyperlink-modal-container.is-visible {
      visibility: visible;
      opacity: 1;
      .modal-dialog {
        position: relative;
        width: 80%;
        max-width: 80%;
        background: white;
        overflow: auto;
        cursor: default;
        background: #ffffff;
        border: 0.063rem solid #dee1ed;
        box-shadow: 0rem 0.063rem 0.125rem rgba(0, 0, 0, 0.15);
        border-radius: 0.125rem;
      }
      .modal-dialog > * {
        padding: 1rem;
      }
      .modal-header {
        border-radius: 0.3125rem 0.3125rem 0 0;
        background-color: white;
        color: #576375;
        font-style: normal;
        font-weight: 600;
        font-size: 1rem;
        line-height: 1.5rem;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        span.modal-title {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        display: flex;
        flex-direction: row;
        span.close-button {
          display: flex;
          flex-direction: row;
        }
      }
      .modal-content {
        font-style: normal;
        font-weight: 400;
        font-size: 0.875rem;
        line-height: 1.25rem;
        color: #2d9de7;
        p {
          overflow: auto;
          height: inherit;
          word-wrap: break-word;
        }
      }
      .modal-footer {
        display: flex;
        flex-direction: row;
        justify-content: end;
        > :first-child {
          margin-right: 0.5rem;
        }
      }
    }
    > .hyperlink-edit-modal-container {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 10;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 1rem;
      background: rgba(0, 0, 0, 0.5);
      cursor: pointer;
      visibility: hidden;
      opacity: 0;
      transition: all 0.35s ease-in;
    }
    > .hyperlink-edit-modal-container.is-visible {
      visibility: visible;
      opacity: 1;
      .modal-dialog {
        position: relative;
        width: 50%;
        max-width: 50%;
        background: white;
        overflow: auto;
        cursor: default;
        background: #ffffff;
        border: 0.063rem solid #dee1ed;
        box-shadow: 0rem 0.063rem 0.125rem rgba(0, 0, 0, 0.15);
        border-radius: 0.125rem;
      }
      .modal-dialog > * {
        padding: 1rem;
      }
      .modal-header {
        border-radius: 0.3125rem 0.3125rem 0 0;
        background-color: white;
        color: #576375;
        font-style: normal;
        font-weight: 600;
        font-size: 1rem;
        line-height: 1.5rem;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        span.modal-title {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        display: flex;
        flex-direction: row;
        span.close-button {
          display: flex;
          flex-direction: row;
        }
      }
      .modal-content {
        font-style: normal;
        font-weight: 400;
        font-size: 0.875rem;
        line-height: 1.25rem;
        padding-top: 0rem;
        padding-bottom: 0rem;

        .modal-form {
          display: flex;
          flex-direction: column;

          label {
            font-style: normal;
            font-weight: 600;
            font-size: 0.875rem;
            line-height: 1.25rem;
            display: flex;
            align-items: flex-end;
            color: #576375;
          }
          input {
            margin-bottom: 1rem;
            min-height: 2.5rem;
            width: 100%;
            background-color: #f7f7fb;
            border: 0.0625rem solid transparent;
            border-radius: 0.125rem;
            max-height: 4.875rem;
            display: flex;
            color: #143b52;
            padding: 0.125rem 0.625rem 0.125rem 0.25rem;
            font-weight: 400;
            font-size: 0.875rem;
            line-height: 1.25rem;
          }
          input:last-child {
            color: #2d9de7;
          }
          input:focus,
          input:focus-within,
          input:focus-visible {
            border: $borderline solid $grey70;
            background-color: $grey93;
            outline: none;
          }
        }
      }
      .modal-footer {
        display: flex;
        flex-direction: row;
        justify-content: end;
        > :first-child {
          margin-right: 0.5rem;
        }
      }
    }
    > .na-body-message {
      display: flex;
      flex-direction: column-reverse;
      min-height: 21rem;
      > .na-body-message-send {
        display: flex;
        align-items: center;
        justify-content: center;
        > .na-body-message-send-button {
          display: flex;
          align-items: center;
          > .time-wrapper {
            display: flex;
            height: auto;
            width: auto;
            justify-content: center;
            align-items: center;
            column-gap: 10%;
            margin-right: $tiny;
            > .time-text-wrapper {
              display: flex;
              flex-direction: column;
              > div {
                font-family: "Work Sans";
                font-style: normal;
                font-weight: 500;
              }
              > .time-text {
                color: #8592ad;
                width: $xxlarge;
                height: $medium;
                font-size: 0.625rem;
                line-height: $medium;
                letter-spacing: 0$tiny;
                text-transform: uppercase;
              }

              > .time-text-minutes {
                color: #000000;
                width: 4.75rem;
                height: $base;
                font-size: $medium;
                line-height: $base;
              }
            }
          }
          position: absolute;
          right: $small;
          bottom: $small;
          display: flex;
          flex-direction: row;
          button {
            margin: 0 $tiny;
            border-radius: $atom 0 0 $atom;
          }
          span:first-child {
            button {
              margin: 0 $tiny;
            }
          }
          span:last-child {
            button {
              margin-right: 0;
            }
          }
        }
        > .na-body-message-send-closed {
          width: $semi;
          border-left: $borderline solid $white100;
          > button {
            padding: 0 $atom;
            border-radius: 0 $atom $atom 0;
          }
          > .send-closed-popup {
            background-color: $white100;
            position: absolute;
            border-radius: $atom;
            box-shadow: 0 $small $medium rgba(0, 0, 0, 0.1), 0 $tiny $semi rgba(0, 0, 0, 0.1);
            top: -$xlarge;
            right: 0;
            padding: $atom $tiny;
            > p {
              @include caption;
              white-space: nowrap;
              padding: $small $medium;

              &:hover {
                cursor: pointer;
                background-color: $grey93;
              }
            }
          }
        }
      }
    }

    .na-body-title.na-body-child {
      .sb-wrapper {
        .sb-input {
          font-weight: 400;
          font-size: 0.875rem;
          line-height: $semi;
        }
      }
    }

    .na-body-title.na-body-child.na-body-template-selector {
      display: flex;
      flex-direction: row;
      background-color: $grey98;
      .sb-wrapper {
        width: 96%;
      }
      .na-body-cc-search {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }
  }
}

.na-body-to-chip {
  border-radius: $atom;
  padding-right: 0.375rem;
  padding-bottom: 0;
  border: none;
  background-color: $grey96;
  fill: $grey60;
}

.MuiModal-root.MuiPopover-root.MuiMenu-overrides.activity-types-list-menu {
  .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiMenu-paper {
    width: 41%;

    .MuiMenuItem-root.MuiMenuItem-gutters {
      padding-left: $medium;
      padding-right: $medium;
    }
    .icon {
      height: $large;
      width: $large;
      border-radius: 50%;
      background-color: $grey90;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 0.6875rem;

      svg {
        width: $medium;
      }
    }
  }
}

.activity-template-selector-autocomplete + .MuiAutocomplete-popper,
.activity-to-autocomplete + .MuiAutocomplete-popper {
  left: -$xlarge;
  width: 97.5% !important;

  .MuiPaper-root {
    .MuiAutocomplete-listbox {
      li.MuiAutocomplete-option {
        display: flex;
        flex-direction: row;
        gap: $small;
        padding: 0.5625rem $base;
        .contact-item-profile-tag {
          height: $larger;
          width: $larger;
          border-radius: 50%;
          background-color: $grey90;
          display: flex;
          align-items: center;
          justify-content: center;
          svg,
          img {
            fill: $grey60;
            width: 1.125rem;
            height: 1.125rem;
          }
        }

        div.contact-info-item {
          display: flex;
          flex-direction: column;
          span.contact-name {
            font-style: normal;
            font-weight: 400;
            font-size: 0.875rem;
            line-height: $semi;
            display: flex;
            align-items: flex-end;
            color: $primary;
          }
          span.contact-email {
            font-style: normal;
            font-weight: 500;
            font-size: $medium;
            line-height: $base;
            display: flex;
            align-items: flex-end;
            color: $grey50;
          }
        }
      }
    }
  }
}

.MuiModal-root.MuiPopover-root.MuiMenu-overrides.template-types-list-menu {
  left: -1.875rem;
  .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiMenu-paper {
    width: 31.1%;
  }
}
