@use "../../../css/index" as *;

.uc-wrapper > .head {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: $backgroundARContent;
  height: 3.5rem;
  flex: 0 0 auto;
  > .accountInfo-wrapper {
    display: flex;
    flex-direction: row;
  }

  > .userInfo-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-right: $base;
    gap: 0.875rem;

    > .last-sync-wrapper {
      color: $grey60;
      gap: $small;
      display: flex;
      align-items: center;
      .light {
        padding-top: 0.07rem;
        @include caption-sm;
        & + .MuiIconButton-root {
          > svg {
            animation: rotate 2s linear infinite;
          }
        }
      }
      .bold {
        @include caption-med;
      }

      .MuiButtonBase-root {
        padding: 0;
      }
    }
  }
}

// Set profile bar color themes for various workspace types here
.uc-wrapper > .head.ap-theme-content {
  background: $backgroundAPContent;
}

.uc-wrapper > .head.ar-theme-content {
  background: $backgroundARContent;
}
