@use "../../../../../css/index" as *;

.details-tab-container {
  display: grid;
  grid-template-rows: 3.5rem auto;
  height: 100%;
  width: 100%;
  > .head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 3.5rem;
    padding: 0 $base 0 $base;
    border-bottom: $borderline solid $grey94;
    > .tabs-wrapper {
      display: flex;
      gap: $base;
      height: 100%;
      > .tab {
        display: flex;
        align-items: center;
        height: 100%;
        color: $grey50;
        cursor: pointer;
        border-bottom: $atom solid transparent;
        &:hover {
          color: $primary;
        }
        &-active {
          color: $primary;
          border-bottom: $atom solid $primary;
        }
      }
    }
  }

  .body {
    position: relative;
    > .loading-wrapper {
      top: 0;
      left: 0;
    }
    overflow: auto;
    .infinite-scroll-component__outerdiv .infinite-scroll-component {
      height: calc(100vh - 16.06rem) !important;
      padding: $base $base 0 $base !important;
    }
    .infinite-scroll-component::-webkit-scrollbar {
      width: $small;
      padding: $atom;
      background: transparent;
      transform: translate(0, 0);
      transition: background-color 0.3s;
    }
    .infinite-scroll-component::-webkit-scrollbar-button {
      height: $xlarge;
    }
    .infinite-scroll-component::-webkit-scrollbar-thumb {
      background: #dee1ed;
      border-radius: $small;
      &::before {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        display: block;
      }
    }
    .infinite-scroll-component::-webkit-scrollbar-track {
      background-clip: content-box;
    }
  }

  .remove-padding {
    padding: 0;
    .infinite-scroll-component__outerdiv .infinite-scroll-component {
      padding: 0 !important;
    }
  }
}
