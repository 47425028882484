@use "../../../css/index" as *;

.loading-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  > img {
    animation: rotate 2s linear infinite;
  }
}

.loading-wrapper-relative {
  position: relative;
}

.loading-cell {
  position: absolute;
  width: 100%;
  height: 100%;
}

// Loading Dots implementation
.dot-container {
  :nth-child(0) {
    animation-delay: 0s;
  }
  :nth-child(1) {
    animation-delay: 0.2s;
  }
  :nth-child(2) {
    animation-delay: 0.4s;
  }
  :nth-child(3) {
    animation-delay: 0.6s;
  }
  :nth-child(4) {
    animation-delay: 0.8s;
  }
}
.dot-div {
  height: 0.625rem;
  width: 0.625rem;
  border-radius: 50%;
  background-color: $white100;
  display: inline-block;
  margin: 0.4rem;
  animation: scaling 1.75s ease-in-out infinite;
}
