@use "../../../css/index" as *;

.summary-header {
  background-color: $grey98;
  border-radius: $atom;
  height: 3.5rem;
  margin-bottom: $base;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 4rem;
  .summary-item {
    @include body2;
    color: $grey10;
    display: flex;
    flex-direction: row;
    align-items: center;
    > .text-label {
      @include body1;
      color: $grey60;
    }
  }
}
