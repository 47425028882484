@import "../../../css/index.scss";

.redirect-form-loader {
  height: 50vh;
}

.redirect-form-container {
  display: flex;
  flex-direction: column;

  width: 37.5rem;
  padding: $xlarge;
  background-color: white;
  border: 1px solid #dee1ed;

  > .form-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
    > .header {
      @include h2;
      text-align: center;
      color: $primary;
      width: 19.68rem;
    }
    > .sub-header {
      @include h3;
      font-weight: 400;
      text-align: center;
      width: 30rem;
      margin-top: $base;
      margin-bottom: $semi;
    }
  }
  > .form-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    .sb-wrapper,
    .ta-wrapper {
      margin-top: $semi;
    }
    .redirect-submit-btn {
      width: 100%;
      margin-top: $large;
    }
  }
}
