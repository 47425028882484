@import "../../../../../../../css/index";

.onboard-activity {
  display: grid;
  grid-template-columns: 4.25rem 62% 25%;

  &-icon {
    vertical-align: middle;
  }
  &-body {
    &-header {
      @include h3;
    }
    &-text {
      @include caption;
      font-weight: 400;
      margin: 0;
      > .purple-highlight {
        @include body4;
        color: $purple;
      }
    }
  }
  &-action {
    .request-onboard-btn {
      padding: 10px 16px;
      background: $purple;
      color: $white100;
      border-radius: 6px;

      &:hover {
        background: $purple;
        color: $white100;
      }
    }
  }
  &-action,
  &-icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
