@use "../../css/index.scss" as *;

#logout-success-container {
  width: 100vw;
  height: 100vh;
  p {
    color: $primary;
    @include body1;
    margin-top: $base !important;
  }
}
