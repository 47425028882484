@import "./css/index.scss";

body {
  background-color: $backgroundARContent;
}

.magic-app-container {
  display: grid;
  grid-template-rows: 5rem auto auto;
  justify-content: center;

  .app-header {
    padding: auto 0 auto 0;
    margin: auto;
    > img {
      height: 3.125rem;
      width: auto;
    }
    > .account-name {
      @include h2;
      color: $primary;
    }
  }

  .app-footer {
    height: 4.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: $base 0 $base 0;
    > .footer-txt {
      @include body1;
      margin-right: $small;
    }
    > .footer-logo {
      > svg {
        height: 1.5rem;
        width: auto;
      }
    }
  }
}
