@use "../../../../../../css/index.scss" as *;

.no-streams-wrapper {
  padding-left: $base;
  .no-streams {
    @include body1;
    padding: $base 0 $medium 0;
  }
}

.ras-container {
  position: relative;
  display: flex;
  flex-direction: column;
  > .ras-item-container {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    > .ras-icon {
      padding-right: $large;
      flex: 0 0 auto;
      .icon-container {
        height: $xlarge;
        width: $xlarge;
        background-color: $grey90;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    > .ras-payment-details-container {
      padding-right: $base;
      flex: 1 1 auto;
      width: $base;
      .header {
        padding-bottom: $tiny;
      }
      > .subheader {
        color: $grey60;
        padding-bottom: $base;
      }

      .see-stream-button {
        white-space: nowrap;
        background: $grey98;
        border: $borderline solid $grey90;
        border-radius: $atom;
      }
    }

    > .status-container {
      flex: 0 0 auto;
    }
  }
  > .rs-divider {
    margin: $large 0 $base 0;
  }
}
