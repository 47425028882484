@use "../../../../css/index" as *;

// ! Main Containers
.aging-wrapper {
  @include widgetCard(100%, 100%);
  display: flex;
  padding: 0;

  > .aging-head {
    display: flex;
    align-items: center;
    flex: 0 0 auto;

    > .aging-title {
      @include caption;
      color: $grey60;
      white-space: nowrap;
      > .pretext {
        @include h4;
        font-weight: 400;
      }
    }
  }

  > .aging-body {
    display: flex;
    width: 100%;
    position: relative;
    flex: 1 0 auto;
    flex-direction: row-reverse;
    > .donut-chart {
      width: 9.5rem;
      flex: 1 1 auto;
      position: relative;
      > .aging-pie-wrapper {
        position: absolute;
        width: 100%;
        height: 100%;
        bottom: 0;
        left: 0;
      }
    }
    > .aging-details {
      flex: 1 1 auto;
      max-width: 60%;
      white-space: nowrap;
      > .total {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        > .amount {
          @include h1;
          white-space: nowrap;
          padding-right: 0.375rem;
        }
        > .currency-suffix {
          .suffix-title {
            @include caption;
          }
        }
      }
      > .breakdown {
        > .breakdown-item {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding-bottom: $tiny;
          > .status-container {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            > .status-indicator {
              height: $small;
              width: $small;
              border-radius: 50%;
              background: red;
              margin-right: $small;
            }
            > .late-status {
              @include caption;
            }
          }
          > .late-amount {
            @include caption-med;
            padding-right: $base;
          }
        }
      }
    }
  }
}

.clickable {
  cursor: pointer;
  path:hover {
    cursor: pointer;
  }
}

.non-clickable {
  cursor: default;
  path:hover {
    cursor: default;
  }
}

// ! Legend

.aging-legend {
  position: absolute;
  height: auto;
  width: 50%;
  bottom: 0;
  right: 0;
  top: 45%;
  transform: translateY(-50%);
}
